<template>
  <div class="app-layout -home">
    <div class="header">
      {{ $t('page.home.welcome') }}
    </div>
    <div class="content">
      <router-link :to="{ name: target.route }">
        {{ $t(target.label) }}
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    props: {
      target: { type: Object, required: true },
    },
  };
</script>
