var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"app-header site-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"branding"},[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'home' }}},[_c('img',{attrs:{"src":require('@/assets/images/logos/berghilfe/logo-reduced.svg').default}}),_c('span',{staticClass:"screenreader-only"},[_vm._v(_vm._s(_vm.$t('navigation.home')))])])],1),_c('div',{staticClass:"navigation"},[_c('ul',{staticClass:"site-navigation"},_vm._l((_vm.navigation),function(ref,index){
var name = ref.name;
var classes = ref.classes;
var label = ref.label;
return _c('li',{key:("navigation-item-" + index),staticClass:"item"},[_vm._t(("navigation-" + name),[_c('router-link',{key:("navigation-" + index),class:("link " + (classes || '')),attrs:{"to":{ name: name }}},[_vm._v(" "+_vm._s(_vm.$t(("navigation." + label)))+" ")])])],2)}),0)]),_c('div',{staticClass:"actions"},[_c('div',{ref:"menu",class:{ 'dropdown-menu': true, '-open': _vm.open }},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"trigger -with-icon",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('icon-set',{attrs:{"icon":"person"}}),_vm._v(" "),_c('span',{staticClass:"_hidden-lg-down"},[_vm._v(_vm._s(_vm.user.name))]),_c('div',{staticClass:"caption"},[_vm._v(" ID "+_vm._s(_vm.user.externalId)+" ")])],1),_c('ul',{staticClass:"menu"},[_vm._l((_vm.menu),function(ref,index){
var name = ref.name;
var icon = ref.icon;
var classes = ref.classes;
var label = ref.label;
return _c('li',{key:("menu-item-" + index),staticClass:"item"},[_c('router-link',{key:("menu-" + index),class:("link " + (classes || '')),attrs:{"to":{ name: name }}},[_c('icon-set',{attrs:{"icon":icon}}),_vm._v(" "+_vm._s(_vm.$t(("navigation." + label)))+" ")],1)],1)}),_c('li',{staticClass:"item"},[_c('a',{staticClass:"link",attrs:{"href":"/logout"}},[_c('icon-set',{attrs:{"icon":"logout"}}),_vm._v(" "+_vm._s(_vm.$t('navigation.logout'))+" ")],1)])],2)])])])]),(_vm.isImpersonation)?_c('div',{staticClass:"impersonation"},[_c('notification-panel',{attrs:{"type":"info","message":((_vm.$t('navigation.impersonation.impersonating')) + " " + (_vm.user.name)),"closeable":false,"actions":[_vm.exitImpersonationAction]},on:{"run-action":function($event){return $event.handler()}}})],1):_vm._e(),(!_vm.$store.state.overlay.visible)?_c('notification',{staticClass:"notification"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }