<template>
  <div class="contact-card">
    <strong
      v-if="title"
      class="title"
    >{{ title }}</strong>

    <span
      v-if="name"
      class="name"
    >{{ name }}</span>

    <span
      v-if="street || streetNumber"
      class="street"
    >{{ street }} {{ streetNumber }}</span>

    <span
      v-if="zip || place"
      class="zipcity"
    >{{ zip }} {{ place }}</span>

    <a
      v-if="phone"
      class="phone"
      :href="`tel:${phone}`"
    >{{ phone }}</a>

    <a
      v-if="email"
      class="email"
      :href="`mailto:${email}`"
    >{{ email }}</a>
  </div>
</template>

<script>
  export default {
    name: 'ContactCard',
    props: {
      title: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      street: {
        type: String,
        default: null,
      },
      streetNumber: {
        type: String,
        default: null,
      },
      zip: {
        type: String,
        default: null,
      },
      place: {
        type: String,
        default: null,
      },
      phone: {
        type: String,
        default: null,
      },
      email: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .contact-card {
    > .title {
      font-weight: bold;
    }

    > .title,
    > .name,
    > .street,
    > .zipcity,
    > .phone {
      display: block;
    }

    > .title + .street {
      margin-top: 0.5space;
    }

    > .name {
      margin-bottom: 0.5space;
    }
  }
</style>
