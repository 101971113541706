import Donor from '@/domain/donor/Donor';
import Partner from '@/domain/user/Partner';

export default class DonorConverter {
  constructor(convertReservation) {
    this.convertReservation = convertReservation;
  }

  convertDonor(responseData) {
    return new Donor({
      ...responseData,
      manager: responseData.manager
        ? new Partner(responseData.manager) : null,
      contactPerson: responseData.contactPerson
        ? new Partner(responseData.contactPerson) : null,
      reservations: responseData.reservations
        ? responseData.reservations.map((r) => this.convertReservation(r)) : [],
      lastUsageInList: responseData.lastUsageInList
        ? new Date(responseData.lastUsageInList * 1000) : null,
    });
  }
}
