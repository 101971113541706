<template>
  <div
    v-if="visible"
    class="overlay-container"
    @click.capture="onClickOutside"
  >
    <div
      ref="content"
      class="content"
    >
      <button
        type="button"
        class="close"
        @click="close"
      />

      <notification
        v-if="$store.state.overlay.visible"
        class="notification"
      />

      <slot />
    </div>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';

  import Notification from '@/views/Notification.vue';
  import OverlayModule from '@/domain/overlay/OverlayModule';

  const { mapActions } = createNamespacedHelpers(OverlayModule.Namespace);

  export default {
    name: 'OverlayContainer',
    components: {
      Notification,
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        keyDown: null,
      };
    },
    watch: {
      visible: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.onOpen();
          } else {
            this.onClose();
          }

          document.documentElement.classList.toggle('is-locked', newValue);
        },
      },
    },
    mounted() {
      this.keyDown = this.onKeyDown.bind(this);

      window.addEventListener('keydown', this.keyDown);
    },
    beforeDestroy() {
      if (this.keyDown) {
        window.removeEventListener('keydown', this.keyDown);
      }
    },
    methods: {
      ...mapActions([
        OverlayModule.Actions.onOpen,
        OverlayModule.Actions.onClose,
      ]),
      close() {
        if (this.visible) {
          const payload = {
            cancel: false,
            close: () => this.$emit('close'),
          };

          this.$eventBus.$emit('overlay.beforeclose', payload);

          if (payload.cancel === true) {
            return;
          }

          this.$emit('close');
        }
      },
      onClickOutside({ target: $target }) {
        if (this.$refs.content.contains($target) === false) {
          this.close();
        }
      },
      onKeyDown({ key }) {
        if (key === 'Escape') {
          this.close();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@gridonic/components/styles/button-plain";
  @import "~@/sass/meta";
  @import "~include-media/dist/include-media";

  .overlay-container {
    background-color: rgba($sbh-palette-black, 0.5);
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 3space;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-overlay;

    > .content {
      background-color: $sbh-palette-white;
      box-shadow: 0 10px 20px -6px rgba($sbh-palette-black, 0.5);
      margin: auto;
      max-width: 1270px;
      padding: 2space;
      position: relative;
      width: 100%;

      > .close {
        @include gd-button-plain;

        padding: 1space;
        position: absolute;
        right: 1.5space;
        top: 0.75space;

        &::after {
          content: "\d7";
        }
      }
    }

    @include media(">md", "<=lg") {
      padding: 1space;
    }

    @include media("<=md") {
      padding: 0.5space;
    }
  }
</style>
