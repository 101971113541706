<template>
  <section
    class="pdf-page"
    :class="{ '-preview': isPreview }"
    :style="{
      '--page-margin-top': marginTop
    }"
  >
    <div class="header">
      <span
        class="logo"
        :class="[ `-${locale}` ]"
      />
    </div>

    <slot />

    <div
      class="footer"
    >
      <div>{{ $t('export.projectList.address.line1') }}</div>
      <div>{{ $t('export.projectList.address.line2') }}</div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'PdfPage',
    props: {
      locale: {
        type: String,
        default: 'de',
      },
      isPreview: {
        type: Boolean,
        default: false,
      },
      marginTop: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
    @import "@/sass/components/_project-list-export-layout";

  .pdf-page {
    position: relative;
    padding: var(--page-margin-top, $ple-page-project-margin-top)
      $ple-page-margin-right $ple-content-margin-bottom $ple-page-margin-left;
    height: 100vh;

    &.-preview {
      height: 297mm;
    }

    > .header {
      position: absolute;
      padding: $ple-logo-page-margin-top 0 0 $ple-logo-page-margin-left;
      top: 0;
      left: 0;

      > .logo {
        background: transparent url("../../assets/images/logos/berghilfe/logo-de.svg") no-repeat;
        background-size: contain;
        display: block;
        width: $ple-logo-width;
        height: 30mm;

        &.-de {
          background-image: url("../../assets/images/logos/berghilfe/logo-de.svg");
        }

        &.-fr {
          background-image: url("../../assets/images/logos/berghilfe/logo-fr.svg");
        }
      }
    }

    > .footer {
      position: absolute;
      bottom: 0;
      left: 0;

      color: #00635b;
      font-size: $ple-footer-font-size;
      font-weight: 700;
      line-height: $ple-footer-line-height;
      padding: 0 0 $ple-page-margin-bottom $ple-page-margin-left;
      text-align: left;
      width: 100%;
    }
  }
</style>
