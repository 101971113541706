export default class Reservation {
  constructor(data) {
    this.data = data;
  }

  /**
   * @returns {int}
   */
  get sextantId() {
    return this.data.sextantId;
  }

  /**
   * @returns {ReservationStatus}
   */
  get status() {
    return this.data.status || null;
  }

  set status(value) {
    this.data.status = value;
  }

  /**
   * @returns {Partner}
   */
  get manager() {
    return this.data.manager || null;
  }

  /**
   * @returns {Donor}
   */
  get donor() {
    return this.data.donor || null;
  }

  set donor(value) {
    this.data.donor = value;
  }

  /**
   * @returns {Project}
   */
  get project() {
    return this.data.project || null;
  }

  set project(value) {
    this.data.project = value;
  }

  /**
   * @return {number}
   */
  get amount() {
    return this.data.amount || 0;
  }

  set amount(value) {
    this.data.amount = value;
  }

  /**
   * @return {Date}
   */
  get valueDate() {
    return this.data.valueDate || null;
  }

  /**
   * @return {Date}
   */
  get untilDate() {
    return this.data.untilDate || null;
  }

  set untilDate(value) {
    this.data.untilDate = value;
  }

  /**
   * @returns {string}
   */
  get note() {
    return this.data.note || null;
  }

  set note(value) {
    this.data.note = value;
  }

  /**
   * @returns {boolean}
   */
  get readonly() {
    return !this.project.id || this.data.readonly || false;
  }

  /**
   * @returns {boolean}
   */
  get isDefaultUntilDateUsed() {
    if (!this.valueDate || !this.untilDate) {
      return false;
    }

    const diffTime = Math.abs(this.untilDate - this.valueDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Around 28 and 31 days is okay, must not be totally exact
    return diffDays >= 28 && diffDays <= 31;
  }
}
