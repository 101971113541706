<template>
  <div
    class="export-preview"
  >
    <pdf-page
      :is-preview="true"
      :locale="locale"
    >
      <project-list-export-project
        :project="project"
        :use-reservations="false"
        :reservation="reservationOrDummy"
        :locale="locale"
      />
    </pdf-page>
  </div>
</template>

<script>
  import PdfPage from '@/components/pdf/PdfPage.vue';
  import ProjectListExportProject from '@/components/list/ProjectListExportProject.vue';

  import Project from '@/domain/project/Project';
  import Reservation from '@/domain/donor/Reservation';

  export default {
    name: 'ProjectListExportPreview',
    components: { PdfPage, ProjectListExportProject },
    props: {
      project: {
        type: Project,
        required: true,
      },
      reservation: {
        type: Reservation,
        default: null,
      },
      locale: {
        type: String,
        required: true,
      },
    },
    computed: {
      reservationOrDummy() {
        return this.reservation || new Reservation({});
      },
    },
  };
</script>

<style lang="scss" scoped>
  .export-preview {
    width: 210mm; height: 297mm;
    margin: 2space auto;
    background: white;

    border: 1px solid black;
  }
</style>
