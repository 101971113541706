export default class ProjectFinancePlan {
  constructor(data) {
    this._projectId = data.projectId;
    this._investmentVolume = data.investmentVolume;
    this._plannedAmount = data.plannedAmount;
    this._paidAmount = data.paidAmount;
    this._reservedAmount = data.reservedAmount;
    this._outstandingBalance = data.outstandingBalance;
  }

  /**
   * @returns {int}
   */
  get projectId() {
    return this._projectId;
  }

  /**
   * @returns {int}
   */
  get investmentVolume() {
    return this._investmentVolume;
  }

  set investmentVolume(value) {
    this._investmentVolume = value;
  }

  /**
   * @returns {int}
   */
  get plannedAmount() {
    return this._plannedAmount;
  }

  /**
   * @returns {int}
   */
  get paidAmount() {
    return this._paidAmount;
  }

  /**
   * @returns {int}
   */
  get reservedAmount() {
    return this._reservedAmount;
  }

  /**
   * @returns {int}
   */
  get outstandingBalance() {
    return this._outstandingBalance;
  }
}
