import ApiRepository from '@/core/api/ApiRepository';
import ProjectFinancePlan from '@/domain/project/ProjectFinancePlan';

export default class FinanceRepository extends ApiRepository {
  /**
   * @param {int} projectId
   *
   * @returns {Promise<ProjectFinancePlan>}
   */
  async readFinancePlanForProject(projectId) {
    const result = await this.connector.read(
      'api_project_finance_read', {
        projectId,
      },
    );

    return new ProjectFinancePlan(result.finance_plan);
  }
}
