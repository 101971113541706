<template>
  <div class="is-loading">
    <slot
      name="alwaysBefore"
      :result="result"
    />

    <slot
      name="indicator"
      :isWaiting="isWaiting"
      :label="label"
    >
      <span
        v-if="isWaiting"
        class="indicator"
        :class="{ '-margined': margined }"
      />

      <span
        v-if="isWaiting && label"
        class="label"
      >{{ label }}</span>
    </slot>

    <slot
      v-if="result"
      name="wait"
      :result="result"
    />

    <slot
      name="alwaysAfter"
      :result="result"
    />
  </div>
</template>

<script>
  import NotificationType from '@/domain/notification/NotificationType';

  export default {
    name: 'IsLoading',
    props: {
      // eslint-disable-next-line vue/require-prop-types
      request: {
        required: true,
        default: null,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      errorLabel: {
        type: String,
        required: false,
        default: '',
      },
      delay: {
        type: Number,
        required: false,
        default: 0,
      },
      margined: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        result: null,
        isWaiting: false,
        error: null,
      };
    },
    watch: {
      request: {
        immediate: true,
        async handler(newValue) {
          if (newValue instanceof Promise) {
            const timeoutId = window.setTimeout(() => {
              this.isWaiting = true;
              this.result = null;
            }, this.delay);

            try {
              this.result = await newValue;
            } catch (error) {
              this.onError(error);
            } finally {
              this.isWaiting = false;
              window.clearTimeout(timeoutId);
            }

            return;
          }

          this.result = newValue;
          this.isWaiting = false;
        },
      },
    },
    methods: {
      onError(error) {
        this.error = error;

        if (this.errorLabel) {
          this.$notify(NotificationType.DANGER, this.errorLabel);
        }

        this.$emit('error', error);

        console.log(this.error);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .is-loading {
    --spinner-size: 48px;
    --spinner-width: 3px;

    min-height: var(--spinner-size);

    > .indicator {
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      animation: spin 1.4s linear infinite;
      border-color: $color-brand-primary rgba($color-lines, 0.25) rgba($color-lines, 0.25);
      border-radius: 50%;
      border-style: solid;
      border-width: var(--spinner-width);
      display: block;
      height: var(--spinner-size);
      margin: 0 auto;
      opacity: 1;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
      width: var(--spinner-size);

      &.-margined {
        margin: 2space auto;
      }
    }

    > .label {
      display: flex;
      justify-content: center;
      margin-top: 1space;

      &::after {
        animation: ellipsis steps(1) 1.4s infinite;
        content: "\2026";
      }

      @keyframes ellipsis {
        0% { clip-path: polygon(0 100%, 0 0, 0 0, 0 100%); }
        25% { clip-path: polygon(33% 100%, 33% 0, 0 0, 0 100%); }
        50% { clip-path: polygon(66% 100%, 66% 0, 0 0, 0 100%); }
        75% { clip-path: polygon(100% 100%, 100% 0, 0 0, 0 100%); }
        100% { clip-path: polygon(0 100%, 0 0, 0 0, 0 100%); }
      }
    }

    > .error {
      display: flex;
      justify-content: center;
      margin-top: 1space;
    }
  }
</style>
