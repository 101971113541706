export default class NotificationAction {
  constructor({ text, handler }) {
    this._text = text;
    this._handler = handler;
  }

  /**
   * @returns {string}
   */
  get text() {
    return this._text;
  }

  set text(value) {
    this._text = value;
  }

  /**
   * @returns {Function}
   */
  get handler() {
    return this._handler;
  }

  set handler(value) {
    this._handler = value;
  }
}
