<template>
  <div class="project-details-finances">
    <dl class="finances">
      <dt class="label -head">
        {{ $t('label.financeProject') }}
      </dt>
      <dd class="value -head">
        {{ $t('label.amount') }} {{ $t('currency.default.unit') }}
      </dd>

      <dt class="label">
        {{ $t('label.investmentVolume') }}
      </dt>
      <dd class="value">
        {{ financePlan.investmentVolume|format({ style: 'decimal' }) }}
      </dd>

      <dt class="label">
        {{ $t('label.shareOfBerghilfe') }}
      </dt>
      <dd class="value">
        {{ financePlan.plannedAmount|format({ style: 'decimal' }) }}
      </dd>

      <dt class="label">
        {{ $t('label.paidAmount') }}
      </dt>
      <dd class="value">
        {{ financePlan.paidAmount|format({ style: 'decimal' }) }}
      </dd>

      <dt
        class="label -link"
        @click="isReservationsOverlayVisible = true"
      >
        {{ $t('label.reservedAmount') }}
      </dt>
      <dd class="value">
        {{ financePlan.reservedAmount|format({ style: 'decimal' }) }}
      </dd>
    </dl>

    <div class="outstanding">
      <tag-item
        v-if="project.isApprovedButIncompletelyPayed(financePlan)"
        class="incomplete -alert"
        :tag="$t('label.projectApprovedButIncompletelyPayed.short')"
      />

      <div class="label">
        {{ $t('label.outstandingBalance') }}
      </div>

      <div class="amount">
        {{ financePlan.outstandingBalance|format }}
      </div>
    </div>

    <div
      v-if="true"
      class="myamount"
    >
      <div class="label">
        {{ $t('label.myDonationAmount') }}
      </div>

      <p
        v-if="hasReservation"
        class="value"
      >
        {{ reservationForDonor.amount|format }}
      </p>

      <template v-else>
        <form-slider
          v-model="amount"
          :range="{ min: sliderMin, '5%': sliderRange, max: financePlan.outstandingBalance }"
          :hide-label="true"
          class="slider"
          @update="onSliderUpdate"
        />

        <label class="form-control control">
          <currency-input
            ref="currencyInput"
            :currency="currency"
            :locale="locale"
            :precision="0"
            :value="amount"
            :value-as-integer="true"
            :value-range="{ min: 0, max: financePlan.outstandingBalance }"
            class="input"
            @change="onInputChange"
          />
        </label>
      </template>

      <button
        class="button-primary reserve"
        @click="openReservation"
      >
        {{ $t(`label.${reservationForDonor ? 'editReservedAmount' : 'reserveAmount' }`) }}
      </button>
    </div>

    <overlay-container
      :visible="isReservationsOverlayVisible"
      @close="isReservationsOverlayVisible = false"
    >
      <reservation-list
        :name="name"
        :short-name="shortName"
        :reservations="reservations"
        :reserved-amount="financePlan.reservedAmount"
        :outstanding-balance="financePlan.outstandingBalance"
      />
    </overlay-container>

    <overlay-container
      :visible="isDonorReservationOverlayVisible"
      @close="isDonorReservationOverlayVisible = false"
    >
      <reservation
        :donor="activeDonor"
        :finance-plan="financePlan"
        :reservation="reservationForDonor"
        :selected-amount="amount"
        :project="project"
        @updated="onReservationUpdate"
      />
    </overlay-container>
  </div>
</template>

<script>
  import { CurrencyInput } from 'vue-currency-input';

  import ProjectFinancePlan from '@/domain/project/ProjectFinancePlan';
  import { format, currency, locale } from '@/domain/currency/Currency';
  import Donor from '@/domain/donor/Donor';

  import FormSlider from '@/components/form/FormSlider.vue';
  import OverlayContainer from '@/components/OverlayContainer.vue';
  import ReservationList from '@/components/reservation/ReservationList.vue';

  import Reservation from '@/views/Reservation.vue';
  import TagItem from '@/components/TagItem.vue';

  import NotificationType from '@/domain/notification/NotificationType';

  import Project from '@/domain/project/Project';
  import ProjectList from '@/domain/list/ProjectList';

  export default {
    name: 'ProjectDetailsFinances',
    inject: [
      'config',
      'repositories',
    ],
    components: {
      TagItem,
      CurrencyInput,
      FormSlider,
      OverlayContainer,
      ReservationList,
      Reservation,
    },
    filters: {
      format,
    },
    props: {
      financePlan: {
        type: ProjectFinancePlan,
        required: true,
      },
      name: {
        type: String,
        default: null,
      },
      shortName: {
        type: String,
        default: null,
      },
      reservations: {
        type: Array,
        default: null,
      },
      activeList: {
        type: ProjectList,
        default: null,
      },
      activeDonor: {
        type: Donor,
        default: null,
      },
      project: {
        type: Project,
        default: null,
      },
    },
    data() {
      return {
        currency,
        locale,
        amount: 0,
        isReservationsOverlayVisible: false,
        isDonorReservationOverlayVisible: false,
      };
    },
    computed: {
      reservationForDonor() {
        if (!this.hasActiveDonor) {
          return null;
        }

        return (this.reservations || [])
          .find(({ donor }) => donor && donor.id === this.activeDonor.id) ?? null;
      },
      hasActiveDonor() {
        return this.activeDonor !== null;
      },
      hasReservation() {
        return this.reservationForDonor !== null;
      },
      sliderMin() {
        return this.config.donation.amountSliderMin;
      },
      sliderRange() {
        return this.config.donation.amountSliderRange;
      },
    },
    methods: {
      openReservation() {
        if (!this.project) {
          return;
        }

        if (!this.activeList
          || this.reservationForDonor !== null
          || this.activeList.containsProject(this.project.id)
        ) {
          this.isDonorReservationOverlayVisible = true;
          return;
        }

        this.$notifyYesNo(
          NotificationType.WARNING,
          'label.warnReservationForProjectNotInList',
          () => { this.isDonorReservationOverlayVisible = true; },
        );
      },
      onReservationUpdate(reservation) {
        this.isDonorReservationOverlayVisible = false;

        this.$emit('reservation-updated', reservation);
      },
      onSliderUpdate(value) {
        this.$refs.currencyInput.setValue(
          this.amount = value,
        );
      },
      onInputChange(value) {
        if (value < 1000 && value > 0) {
          this.$refs.currencyInput.setValue(this.amount);

          return;
        }

        this.amount = value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-finances {
    > .finances {
      border-bottom: 1px solid $palette-fir-20;
      display: grid;
      grid-row-gap: 1space;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 0 1.875space;
      padding-bottom: 1space;

      > .label,
      > .value {
        font-size: 16px;
        margin: 0;
        padding: 0;

        &.-head {
          border-bottom: 1px solid $palette-fir-20;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.57;
          margin-bottom: 0.375space;
          padding-top: 0.25space;
        }
      }

      > .value {
        font-weight: bold;
        text-align: right;
        padding-left: 1space;
      }

      > .label {
        &.-link {
          color: $sbh-palette-fir;
          text-decoration: underline;
        }
      }
    }

    > .outstanding {
      border-bottom: 1px solid $palette-fir-20;
      padding-bottom: 2space;

      + .myamount {
        margin: 1.4375space 0 0;
      }

      > .incomplete {
        margin-bottom: 1space;
      }

      > .amount {
        font-size: 42px;
        font-weight: bold;
        line-height: 1.19;
        margin-top: 0.125space;
      }
    }

    > .myamount {
      > .value {
        font-size: 42px;
        font-weight: bold;
        line-height: 1.19;
        margin-top: 0.125space;
      }

      > .slider {
        margin-top: 2space;
      }

      > .control {
        margin-top: 2space;

        > .input {
          color: $sbh-palette-black;
          text-align: center;
        }
      }

      > .reserve {
        margin-top: 2.5space;
      }
    }
  }
</style>
