import Canton from '@/domain/master-data/Canton';
import ProjectArea from '@/domain/master-data/ProjectArea';
import ProjectFundingStatus from '@/domain/master-data/ProjectFundingStatus';
import Translator from '@/domain/master-data/Translator';
import MountainZone from '@/domain/master-data/MountainZone';
import Region from '@/domain/master-data/Region';
import ProjectStatus from '@/domain/master-data/ProjectStatus';
import ReservationStatus from '@/domain/donor/ReservationStatus';
import ProjectListStatus from '@/domain/list/ProjectListStatus';
import Copywriter from '@/domain/master-data/Copywriter';

const CANTONS_NOT_IN_MOUNTAIN_ZONES = [
  2, 6, 9,
];

export default class MasterDataRepository {
  constructor(data) {
    this._data = data;

    this._fundingStates = null;
    this._translators = null;
    this._copywriters = null;
    this._projectAreas = null;
    this._cantons = null;
    this._regions = null;
    this._mountainZones = null;
    this._projectStates = null;
    this._reservationStates = null;
    this._projectListStates = null;
  }

  /**
   * @returns {ProjectFundingStatus[]}
   */
  get fundingStates() {
    if (!this._fundingStates) {
      this._fundingStates = (this._data.fundingStates || [])
        .map((s) => new ProjectFundingStatus(s));
    }

    return this._fundingStates;
  }

  /**
   * @returns {Translator[]}
   */
  get translators() {
    if (!this._translators) {
      this._translators = (this._data.translators || [])
        .map((s) => new Translator(s));
    }
    return this._translators;
  }

  /**
   * @returns {Copywriter[]}
   */
  get copywriters() {
    if (!this._copywriters) {
      this._copywriters = (this._data.copywriters || [])
        .map((s) => new Copywriter(s));
    }
    return this._copywriters;
  }

  /**
   * @returns {ProjectArea[]}
   */
  get projectAreas() {
    if (!this._projectAreas) {
      this._projectAreas = (this._data.areas || [])
        .map((a) => new ProjectArea(a));
    }

    return this._projectAreas;
  }

  /**
   * @returns {Canton[]}
   */
  get cantons() {
    if (!this._cantons) {
      this._cantons = (this._data.cantons || [])
        .map((c) => new Canton(c));
    }

    return this._cantons;
  }

  /**
   * @returns {Canton[]}
   */
  get cantonsInMountainZones() {
    return this.cantons.filter((c) => !CANTONS_NOT_IN_MOUNTAIN_ZONES.includes(c.id));
  }

  /**
   * @returns {Region[]}
   */
  get regions() {
    if (!this._regions) {
      this._regions = (this._data.regions || [])
        .map((r) => new Region(r))
        .sort((a, b) => (a.name ?? '').localeCompare(b.name));
    }

    return this._regions;
  }

  /**
   * @returns {MountainZone[]}
   */
  get mountainZones() {
    if (!this._mountainZones) {
      this._mountainZones = (this._data.mountainZones || [])
        .map((m) => new MountainZone(m));
    }

    return this._mountainZones;
  }

  /**
   * @returns {ProjectStatus[]}
   */
  get projectStates() {
    if (!this._projectStates) {
      this._projectStates = (this._data.projectStates || [])
        .map((m) => new ProjectStatus(m));
    }

    return this._projectStates;
  }

  /**
   * @returns {ReservationStatus[]}
   */
  get reservationStates() {
    if (!this._reservationStates) {
      this._reservationStates = (this._data.reservationStates || [])
        .map((r) => new ReservationStatus(r));
    }

    return this._reservationStates;
  }

  /**
   * @returns {ReservationStatus[]}
   */
  get projectListStates() {
    if (!this._projectListStates) {
      this._projectListStates = (this._data.projectListStates || [])
        .map((r) => new ProjectListStatus(r));
    }

    return this._projectListStates;
  }

  /**
   * @param {int} id
   * @returns {ProjectFundingStatus}
   */
  getFundingStatus(id) {
    return this.fundingStates.find((s) => s.id === id);
  }

  /**
   * @param {int} id
   * @returns {Translator}
   */
  getTranslator(id) {
    return this.translators.find((s) => s.id === id);
  }

  /**
   * @param {int} id
   * @returns {ProjectArea}
   */
  getProjectArea(id) {
    return this.projectAreas.find((a) => a.id === id);
  }

  /**
   * @param {int} id
   * @returns {Canton}
   */
  getCanton(id) {
    return this.cantons.find((c) => c.id === id);
  }

  /**
   * @param {int} id
   * @returns {Canton}
   */
  getRegion(id) {
    return this.regions.find((r) => r.id === id);
  }

  /**
   * @param {int} id
   * @returns {MountainZone}
   */
  getMountainZone(id) {
    return this.mountainZones.find((m) => m.id === id);
  }

  /**
   * @param {int} id
   * @returns {ProjectStatus}
   */
  getProjectStatus(id) {
    return this.projectStates.find((s) => s.id === id);
  }

  /**
   * @param {int} id
   * @returns {ReservationStatus}
   */
  getReservationStatus(id) {
    return this.reservationStates.find((r) => r.id === id);
  }

  /**
   * @param {int} id
   * @returns {ProjectListStatus}
   */
  getProjectListStatus(id) {
    return this.projectListStates.find((s) => s.id === id);
  }
}
