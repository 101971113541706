<template>
  <div class="reservation-detail">
    <h2 class="title">
      {{ $t('reservation.detail.title') }}
    </h2>

    <donor-select
      v-if="!localState.donor && !editReservation"
      :donor-request-pending="donorPending"
      @donorChanged="onDonorChanged"
    />
    <donor-info
      v-if="localState.donor"
      :donor="localState.donor"
      class="donorinfo"
    />

    <div
      v-if="project"
      class="project"
    >
      <h3 class="title">
        {{ project.name || $t('label.projectUnknown') }}
      </h3>
      <div class="subtitle">
        {{ project.shortName || `UId: ${project.sextantId}` }}
      </div>
    </div>

    <div
      v-if="projectLists && projectLists.length"
      class="projectlists"
    >
      <div class="title">
        {{ $t('donor.detail.projectLists.title') }}
      </div>

      <table class="table-default">
        <thead>
          <tr>
            <th>{{ $t('donor.detail.projectLists.updatedAt') }}</th>
            <th>{{ $t('donor.detail.projectLists.status') }}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in projectLists"
            :key="`reservation-${index}`"
            class="reservation"
          >
            <td><b>{{ list.updatedAt|date }}</b></td>
            <td>{{ list.status ? list.status.name : '' }}</td>
            <td>
              <router-link
                :to="{ name: 'projectlist', params: { listId: list.id }}"
                class="action"
              >
                <icon-set icon="arrow-right" />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="details">
      <p class="title">
        {{ $t('reservation.detail.reservedAmount') }}
      </p>

      <div class="amount">
        <form-slider
          v-model="localState.amount"
          :range="{ min: sliderMin, '5%': sliderRange, max: outstandingBalance }"
          :hide-label="true"
          class="slider"
          @update="onSliderUpdate"
          @change="triggerChange"
        />

        <label class="form-control control">
          <currency-input
            ref="currencyInput"
            :currency="currency"
            :locale="locale"
            :precision="0"
            :value-as-integer="true"
            :value-range="{ min: 0, max: outstandingBalance }"
            :value="localState.amount"
            class="input"
            @change="onInputChange"
          />
        </label>

        <p class="outstanding">
          {{ $t('reservation.detail.outstanding') }}:
          <strong>{{ remainingOutstandingBalance|format }}</strong>
        </p>
      </div>

      <label class="date form-date">
        <span class="label">
          {{ $t('reservation.detail.untilDate') }}
        </span>

        <form-date
          v-if="readOnly !== true"
          v-model="localState.untilDate"
          mode="single"
          :inline="false"
          @change="triggerChange"
        />
      </label>

      <label class="note form-textarea">
        <span class="label">
          {{ $t('reservation.detail.note') }}
        </span>

        <textarea
          v-model="localState.note"
          class="textarea"
          name="note"
          :disabled="readOnly"
          @change="triggerChange"
        />
      </label>

      <label class="status form-select">
        <span class="label">
          {{ $t('reservation.detail.status') }}
        </span>

        <select
          v-model="localState.status"
          :disabled="readOnly"
          class="select"
          @change="triggerChange"
        >
          <option
            v-for="s in states.filter(state => readOnly || state.canBeSet)"
            :key="`status-${s.id}`"
            :value="s"
          >
            {{ s.name }}
          </option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
  import { CurrencyInput } from 'vue-currency-input';

  import DonorInfo from '@/components/donor/DonorInfo.vue';
  import FormDate from '@/components/form/FormDate.vue';
  import FormSlider from '@/components/form/FormSlider.vue';
  import IconSet from '@/components/IconSet.vue';

  import { format, currency, locale } from '@/domain/currency/Currency';
  import { format as date } from '@/domain/date/Date';
  import Donor from '@/domain/donor/Donor';
  import Project from '@/domain/project/Project';
  import DonorSelect from '@/components/donor/DonorSelect.vue';
  import NotificationType from '@/domain/notification/NotificationType';

  export default {
    name: 'ReservationDetail',
    components: {
      DonorSelect,
      IconSet,
      FormDate,
      FormSlider,
      DonorInfo,
      CurrencyInput,
    },
    inject: [
      'config',
      'repositories',
    ],
    filters: {
      date,
      format,
    },
    props: {
      project: {
        type: Project,
        default: null,
      },
      projectLists: {
        type: Array,
        default: () => [],
      },
      donor: {
        type: Donor,
        default: null,
      },
      amount: {
        type: Number,
        default: 0,
      },
      untilDate: {
        type: Date,
        default: null,
      },
      note: {
        type: String,
        default: null,
      },
      status: {
        type: Object,
        default: null,
      },
      states: {
        type: Array,
        default: () => ([]),
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      outstandingBalance: {
        type: Number,
        default: 0,
      },
      editReservation: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        locale,
        currency,
        request: null,
        oldAmount: this.amount,
        donorPending: false,
        localState: {
          amount: this.amount,
          status: this.status,
          untilDate: this.untilDate,
          note: this.note,
          donor: this.donor,
        },
      };
    },
    computed: {
      remainingOutstandingBalance() {
        return this.outstandingBalance - this.localState.amount;
      },
      donorRepository() {
        return this.repositories.donor;
      },
      sliderMin() {
        return this.config.donation.amountSliderMin;
      },
      sliderRange() {
        return this.config.donation.amountSliderRange;
      },
    },
    methods: {
      onSliderUpdate(value) {
        this.$refs.currencyInput.setValue(
          this.localState.amount = value,
        );
      },
      onInputChange(value) {
        if (value < 1000 && value > 0) {
          this.$refs.currencyInput.setValue(this.localState.amount);

          return;
        }

        this.localState.amount = value;
      },
      async onDonorChanged(id) {
        this.donorPending = true;
        try {
          this.localState.donor = await this.donorRepository.findDonorFromSextant(id);
          this.triggerChange();
        } catch (error) {
          this.donorPending = false;
          this.$notify(NotificationType.DANGER, 'label.donorNotFound');
        }
      },
      triggerChange() {
        // Wait for v-models to be updated, that’s why we nextTick’ing...
        this.$nextTick(() => {
          this.$emit('change', this.localState);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .reservation-detail {
    > .title {
      @include project-details-block-title;
    }

    > .donorinfo {
      margin-bottom: 3space;
    }

    > .project,
    > .details {
      padding-left: 1.75space;
    }

    > .project {
      > .title {
        all: unset;
        font-weight: $font-weight-bold;
      }

      > .subtitle {
        color: #383b41; // not documented in zeplin...
      }
    }

    > .details {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr;
      grid-template-rows: auto auto;
      grid-gap: 2space;

      > .title {
        margin-top: 1.8125space;
        margin-bottom: 0;
      }

      > .amount {
        > .outstanding {
          font-size: 14px;
          line-height: 1.86;
        }
      }

      > .amount,
      > .date,
      > .note,
      > .status {
        grid-row: 2 / -1;
      }

      > .note,
      > .status {
        align-self: flex-start;
      }
    }

    > .projectlists {
      position: relative;
      margin: 2space 0;
      padding-left: 1.75space;
      padding-right: 1.75space;

      > .title {
        @include project-details-block-title;
      }

      > .listlink {
        position: absolute;
        top: 0;
        right: 1.75space;
      }

      td > .action {
        float: right;

        > .icon-set {
          color: $palette-sun-initial;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
</style>
