<template>
  <div
    v-if="localNotification"
    class="notification"
    :class="{ '-hidden': notification === null }"
  >
    <notification-panel
      :type="localNotification.type"
      :message="localNotification.message"
      :actions="localNotification.actions"
      :closeable="true"
      @run-action="runAction($event)"
      @close="close"
    />
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';

  import NotificationModule from '@/domain/notification/NotificationModule';
  import NotificationPanel from '@/components/NotificationPanel.vue';

  const { mapGetters, mapActions } = createNamespacedHelpers(NotificationModule.Namespace);

  export default {
    name: 'Notification',
    components: { NotificationPanel },
    data() {
      return {
        localNotification: null,
      };
    },
    computed: {
      ...mapGetters([
        NotificationModule.Getters.notification,
      ]),
    },
    watch: {
      notification(newValue) {
        if (newValue) {
          this.localNotification = newValue;
        }
      },
    },
    methods: {
      ...mapActions([
        NotificationModule.Actions.runAction,
        NotificationModule.Actions.close,
      ]),
    },
  };
</script>

<style lang="scss" scoped>
  .notification {
    opacity: 1;
    height: 100%;

    &.-hidden {
      opacity: 0;
      height: 0;
      pointer-events: none;

      transition: opacity 1s ease-in-out;
    }
  }
</style>
