/* eslint-disable no-unused-vars */
export default class ApiConnector {
  constructor() {
    if (new.target === ApiConnector) {
      throw new TypeError('cannot construct ApiConnector instances directly');
    }
  }

  async create(name, data, params) {
    throw new Error('not implemented');
  }

  async read(name, params) {
    throw new Error('not implemented');
  }

  async update(name, data, params) {
    throw new Error('not implemented');
  }

  async delete(name, params) {
    throw new Error('not implemented');
  }
}
