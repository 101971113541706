export const PROJECT_LIST_STATUS = {
  PROCESSING: 1,
  SENT: 2,
};

export default class ProjectListStatus {
  constructor(data) {
    this._id = data.id;
    this._name = data.name;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }
}
