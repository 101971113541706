<template>
  <div class="reservation">
    <is-loading
      #wait="{}"
      :request="request"
      class="loading"
      :delay="1"
      :label="loadingLabel"
      :error-label="$t('reservation.detail.error')"
      @error="reload"
    >
      <reservation-detail
        v-if="!(reservation || {}).sextantId"
        :amount="localState.amount"
        :donor="donor"
        :outstanding-balance="outstandingBalance"
        :project="project"
        :project-lists="projectLists"
        :states="reservationStates"
        :status="reservationStates.find(({ id }) => id === 999) || reservationStates.state[0]"
        :until-date="localState.untilDate"
        @change="onChange"
      />

      <reservation-detail
        v-else
        :amount="localState.amount"
        :donor="reservation.donor"
        :note="localState.note"
        :outstanding-balance="outstandingBalance + reservation.amount"
        :project="reservation.project"
        :project-lists="projectLists"
        :read-only="readOnly"
        :states="reservationStates"
        :status="localState.status"
        :until-date="localState.untilDate"
        :edit-reservation="true"
        @change="onChange"
      />

      <button
        v-if="readOnly === false"
        class="submit button-primary"
        :disabled="!donor && !localState.donor"
        @click="save"
      >
        {{ $t('reservation.detail.reserve') }}
      </button>
    </is-loading>
  </div>
</template>

<script>
  import IsLoading from '@/components/IsLoading.vue';
  import ReservationDetail from '@/components/reservation/ReservationDetail.vue';

  import Reservation from '@/domain/donor/Reservation';
  import NotificationType from '@/domain/notification/NotificationType';
  import ProjectFinancePlan from '@/domain/project/ProjectFinancePlan';
  import Donor from '@/domain/donor/Donor';
  import Project from '@/domain/project/Project';
  import { thirtyDaysLater } from '@/domain/date/Date';

  export default {
    name: 'Reservation',
    components: {
      IsLoading,
      ReservationDetail,
    },
    inject: [
      'repositories',
    ],
    props: {
      selectedAmount: {
        type: Number,
        default: 0,
      },
      reservation: {
        type: Reservation,
        default: null,
      },
      project: {
        type: Project,
        default: null,
      },
      financePlan: {
        type: ProjectFinancePlan,
        default: null,
      },
      donor: {
        type: Donor,
        default: null,
      },
      displayProjectLists: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loadingLabel: null,
        request: null,
        localFinancePlan: null,
        projectLists: [],
        localState: {
          amount: this.reservation?.amount || this.selectedAmount,
          status: this.reservation?.status || null,
          note: this.reservation?.note || null,
          untilDate: this.reservation?.untilDate || thirtyDaysLater,
          donor: this.reservation?.donor || this.donor,
        },
      };
    },
    computed: {
      masterDataRepository() {
        return this.repositories.masterData;
      },
      reservationRepository() {
        return this.repositories.reservation;
      },
      financeRepository() {
        return this.repositories.finance;
      },
      projectListRepository() {
        return this.repositories.projectList;
      },
      reservationStates() {
        return this.masterDataRepository.reservationStates;
      },
      readOnly() {
        return (this.reservation && this.reservation.readonly === true) || false;
      },
      outstandingBalance() {
        if (!this.localFinancePlan) {
          return 0;
        }

        return this.localFinancePlan.outstandingBalance;
      },
    },
    async created() {
      this.$eventBus.$on('overlay.beforeclose', this.beforeClose);

      this.reload();
    },
    beforeDestroy() {
      this.$eventBus.$off('overlay.beforeclose', this.beforeClose);
    },
    methods: {
      reload() {
        this.loadingLabel = this.$t('label.isLoading').toString();

        this.request = (async () => {
          if (this.financePlan) {
            this.localFinancePlan = this.financePlan;
          } else if (this.project.id) {
            this.localFinancePlan = await this.financeRepository
              .readFinancePlanForProject(this.project.id);
          }

          if (this.displayProjectLists
            && this.donor && this.donor.id && this.project && this.project.id
          ) {
            this.projectLists = await this.projectListRepository
              .readProjectListsOfDonorAndProject(this.donor.id, this.project.id);
          }

          return this.reservation || new Reservation({});
        })();
      },
      onChange(localState) {
        this.localState = {
          ...this.localState,
          ...localState,
        };
      },
      // eslint-disable-next-line no-unused-vars
      beforeClose(payload) {
        // eslint-disable-next-line no-param-reassign
        // payload.cancel = true;
        //
        // this.$notify(NotificationType.WARNING, 'hello', [new NotificationAction({
        //   text: 'gak',
        //   handler: () => {
        //     payload.close();
        //   },
        // })]);

        // TODO: cancel and notify if there are any changes
      },
      async save() {
        let reservation = null;

        if (this.reservation && this.reservation.sextantId !== null) {
          this.loadingLabel = this.$t('reservation.detail.saving').toString();

          reservation = new Reservation({
            sextantId: this.reservation.sextantId,
            project: this.project,
            donor: this.donor,
          });

          reservation.amount = this.localState.amount;
          reservation.status = this.localState.status;
          reservation.note = this.localState.note;
          reservation.untilDate = this.localState.untilDate;

          this.request = this
            .reservationRepository
            .updateReservation(reservation);
        } else if (this.donor) {
          reservation = new Reservation({
            donor: this.donor,
            project: this.project,
          });

          reservation.amount = this.localState.amount;
          reservation.status = this.localState.status;
          reservation.note = this.localState.note;
          reservation.untilDate = this.localState.untilDate;

          this.request = this
            .reservationRepository
            .createReservation(reservation);
        } else {
          reservation = new Reservation({
            project: this.project,
          });
          reservation.donor = this.localState.donor;
          reservation.amount = this.localState.amount;
          reservation.status = this.localState.status;
          reservation.note = this.localState.note;
          reservation.untilDate = this.localState.untilDate;

          this.request = this
            .reservationRepository
            .createReservation(reservation);
        }

        try {
          reservation = await this.request;
          this.$notify(NotificationType.SUCCESS, 'label.saveSuccess');
        } finally {
          this.$emit('updated', reservation);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .reservation {
    background-color: $sbh-palette-white;

    > .submit {
      float: right;
      margin-top: 3space;
    }
  }
</style>
