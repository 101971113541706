export default class Copywriter {
  constructor(data) {
    this._id = data.id;
    this._name = data.name;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }
}
