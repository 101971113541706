export default class FavoriteModule {
  constructor(favoriteRepository) {
    this.favoriteRepository = favoriteRepository;
  }

  static get Namespace() {
    return 'favorite';
  }

  get namespaced() {
    return true;
  }

  get state() {
    return {
      favorites: null,
    };
  }

  get getters() {
    return {
      [FavoriteModule.Getters.favorites]: (state) => state.favorites,
    };
  }

  get mutations() {
    return {
      [FavoriteModule.Mutations.setFavorites]:
        (state, favorites) => { state.favorites = favorites; },
    };
  }

  get actions() {
    return {
      [FavoriteModule.Actions.load]: async ({ commit }) => {
        commit(
          FavoriteModule.Mutations.setFavorites,
          await this.favoriteRepository.readFavorites(),
        );
      },
      [FavoriteModule.Actions.toggle]: async ({ commit, state }, projectId) => {
        commit(
          FavoriteModule.Mutations.setFavorites,
          await this.favoriteRepository
            .toggleFavoriteForProject(state.favorites || [], projectId),
        );
      },
    };
  }

  static get Getters() {
    return {
      favorites: 'favorites',
    };
  }

  static get Mutations() {
    return {
      setFavorites: 'setFavorites',
    };
  }

  static get Actions() {
    return {
      load: 'load',
      toggle: 'toggle',
    };
  }
}
