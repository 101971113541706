<template>
  <div
    class="content-editor"
    :class="{
      '-compact': compact, '-warning': hasCharCountWarning, '-focused': isEditorFocused
    }"
  >
    <editor-content
      class="content"
      :editor="editor"
    />

    <editor-menu-bubble
      v-if="editable && formattable"
      v-slot="{ commands, isActive, menu }"
      :editor="editor"
      :keep-in-bounds="keepInBounds"
    >
      <div
        class="bubble"
        :class="{ '-active': menu.isActive }"
        :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
      >
        <button
          :class="{ action: true, '-active': isActive.bold() }"
          @click="commands.bold"
        >
          {{ $t('label.headline') }}
        </button>
      </div>
    </editor-menu-bubble>

    <div
      v-if="recommendedCharLimit && isEditorFocused"
      class="chars"
      title="test"
    >
      {{ charCount }} / {{ recommendedCharLimit }}
    </div>
  </div>
</template>

<script>
  import {
    Editor, EditorContent, EditorMenuBubble, Node, Paragraph, Text, Doc,
  } from 'tiptap';

  import { Bold, History, Placeholder } from 'tiptap-extensions';

  // @see https://github.com/ueberdosis/tiptap/issues/96
  class CustomDoc extends Node {
    get name() {
      return 'doc';
    }

    get schema() {
      return {
        content: 'block',
      };
    }
  }

  export default {
    name: 'ContentEditor',
    components: {
      EditorContent,
      EditorMenuBubble,
    },
    model: {
      prop: 'content',
      event: 'change',
    },
    props: {
      content: {
        type: [String, Object],
        required: false,
        default: '',
      },
      editable: {
        type: Boolean,
        default: true,
      },
      formattable: {
        type: Boolean,
        default: true,
      },
      placeholder: {
        type: String,
        default: null,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      recommendedCharLimit: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        keepInBounds: true,
        editor: null,
        json: null,
        text: null,
      };
    },
    computed: {
      isEditorFocused() {
        return this.editor.focused;
      },
      charCount() {
        return (this.editor.state.doc.textContent || '').length;
      },
      hasCharCountWarning() {
        return this.recommendedCharLimit && (this.charCount > this.recommendedCharLimit);
      },
    },
    watch: {
      editable(newValue) {
        if (this.editor) {
          this.editor.setOptions({ editable: newValue });
        }
      },
    },
    created() {
      const extensions = [
        this.formattable ? new Doc() : new CustomDoc(),
        new Text(),
        new Paragraph(),
        new Bold(),
        new History(),
      ];

      if (this.placeholder) {
        extensions.push(
          new Placeholder({
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: false,
          }),
        );
      }

      this.editor = new Editor({
        useBuiltInExtensions: false,
        editable: this.editable,
        content: this.content,
        extensions,
        onUpdate: this.export,
      });
    },
    beforeDestroy() {
      this.editor.destroy();
    },
    methods: {
      export({ state }) {
        this.json = state.doc.toJSON();
        this.text = state.doc.textContent;

        this.$emit('change', {
          json: this.json,
          text: this.text,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";
  @import "@/sass/components/_project-list-export-layout";

  .content-editor {
    position: relative;

    > .content {
      position: relative;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;

      // Remove or adjust this…
      // This applies to generated html from tiptap.
      ::v-deep {
        .ProseMirror {
          outline: none;
        }

        p {
          margin: 1space 0;

          &.is-editor-empty:first-child::before {
            content: attr(data-empty-text);
            font-style: italic;
            opacity: 0.5;
          }
        }

        // Using strong as fake headline
        strong {
          display: block;
        }
      }
    }

    > .bubble {
      background-color: #000;
      border-radius: 5px;
      display: flex;
      margin-bottom: 0.5rem;
      opacity: 0;
      padding: 0.3rem;
      position: absolute;
      transform: translateX(-50%);
      transition: opacity 0.2s, visibility 0.2s;
      visibility: hidden;

      &.-active {
        opacity: 1;
        visibility: visible;
      }

      > .action {
        background: transparent;
        border-radius: 3px;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        margin-right: 0.2rem;
        padding: 0.2rem 0.5rem;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: rgba(#fff, 0.1);
        }

        &.-active {
          background-color: rgba(#fff, 0.2);
        }
      }
    }

    > .chars {
      position: absolute;
      bottom: -6px;
      right: 0;
      transform: translateY(100%);
      font-size: 12px;
      line-height: 13px;
      font-weight: bold;
      color: $palette-fir-50;
    }

    &.-compact {
      > .content {
        font-size: $ple-text-font-size;
        line-height: $ple-text-line-height;

        ::v-deep {
          strong {
            font-size: $ple-text-font-size;
            font-weight: bold;
            line-height: $ple-text-line-height;
            margin: 0;
            display: block;
          }
        }
      }
    }

    &.-focused,
    &.-warning {
      > .content::before {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        border-radius: 6px;
      }
    }

    &.-focused {
      > .content::before {
        background: #f5f5f5;
      }
    }

    &.-warning {
      > .content::before {
        background: lighten($palette-sun-initial, 48%);
      }

      &.-focused {
        > .content::before {
          background: lighten($palette-sun-initial, 44%);
        }
      }

      > .chars {
        color: $palette-sun-initial;
      }
    }
  }
</style>
