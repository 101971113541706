<template>
  <div>
    <div
      class="donor-select"
    >
      <label class="donor-id form-textarea">
        <span class="label">
          {{ $t('donor.select.title') }}
        </span>

        <textarea
          v-model="donorId"
          class="textarea"
          name="note"
          rows="1"
        />
      </label>
      <button
        class="button-primary select-donor"
        :disabled="donorRequestPending"
        @click="setDonor"
      >
        {{ $t('donor.select.action') }}
      </button>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'DonorSelect',
    props: {
      donorRequestPending: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        donorId: '',
      };
    },
    methods: {
      setDonor() {
        this.$nextTick(async () => {
          await this.$emit('donorChanged', this.donorId);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";
.donor-select {
  display: flex;
  padding: 20px;
  .donor-id {
    margin-right: 40px;
  }
}
</style>
