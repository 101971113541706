import Project from '@/domain/project/Project';
import ProjectFinancePlan from '@/domain/project/ProjectFinancePlan';
import Partner from '@/domain/user/Partner';
import ProjectImage from '@/domain/project/ProjectImage';
import ProjectDocument from '@/domain/project/ProjectDocument';
import Coordinates from '@/domain/location/Coordinates';

export default class ProjectConverter {
  constructor(masterData, reservationConverter) {
    this.masterData = masterData;
    this.reservationConverter = reservationConverter;
  }

  convertProject(responseData) {
    return new Project(
      {
        ...responseData,
        fundingStatus: responseData.fundingStatus
          ? this.masterData.getFundingStatus(responseData.fundingStatus) : null,
        readyInternalAt: responseData.readyInternalAt
          ? new Date(responseData.readyInternalAt * 1000) : null,
        projectStatus: responseData.projectStatus
          ? this.masterData.getProjectStatus(responseData.projectStatus) : null,
        area: responseData.area
          ? this.masterData.getProjectArea(responseData.area) : null,
        mountainZone: responseData.mountainZone
          ? this.masterData.getMountainZone(responseData.mountainZone) : null,
        canton: responseData.canton
          ? this.masterData.getCanton(responseData.canton) : null,
        region: responseData.region
          ? this.masterData.getRegion(responseData.region) : null,
        decisionDate: responseData.decisionDate
          ? new Date(responseData.decisionDate * 1000) : null,
        financePlan: responseData.financePlan
          ? new ProjectFinancePlan(responseData.financePlan) : null,
        applicant: responseData.applicant
          ? new Partner(responseData.applicant) : null,
        expert: responseData.expert
          ? new Partner(responseData.expert) : null,
        copywriter: responseData.copywriter
          ? new Partner(responseData.copywriter) : null,
        translator: responseData.translator
          ? new Partner(responseData.translator) : null,
        coordinates: responseData.coordinates
          ? new Coordinates(responseData.coordinates) : null,
        reservations: (responseData.reservations || [])
          .map((r) => this.reservationConverter.convertReservation(r)),
        fallbackImage: responseData.fallbackImage
          ? new ProjectImage(responseData.fallbackImage) : null,
        images: (responseData.images || []).map((i) => new ProjectImage(i)),
        documents: (responseData.documents || []).map((i) => new ProjectDocument({
          ...i, changedAt: i.changedAt ? new Date(i.changedAt * 1000) : null,
        })),
      },
    );
  }

  convertReservation(responseData) {
    return this.reservationConverter.convertReservation(responseData);
  }
}
