<template>
  <div class="image-slider">
    <div
      ref="slider"
      class="slider"
    >
      <div
        v-for="(image, index) in images"
        :key="`image-${index}`"
        :style="`background-image: url(${image.url})`"
        class="slide"
      >
        <icon-set
          v-if="image.isTitleImage"
          icon="active"
          class="title"
        />

        <span
          v-if="image.isSelected"
          class="selected"
        />
      </div>
    </div>

    <button
      v-if="slider"
      type="button"
      :class="{ previous: true, '-disabled': isFirst }"
      @click="slider.prev()"
    >
      <icon-set icon="arrow-left" />
    </button>

    <button
      v-if="slider"
      type="button"
      :class="{ next: true, '-disabled': isLast }"
      @click="slider.next()"
    >
      <icon-set icon="arrow-right" />
    </button>

    <div
      v-if="slider"
      class="dots"
    >
      <button
        v-for="(slide, index) in images.length"
        :key="`dot-${index}`"
        :class="{ dot: true, '-active': current === index }"
        type="button"
        @click="slider.moveToSlideRelative(index)"
      />
    </div>
  </div>
</template>

<script>
  import KeenSlider from 'keen-slider';
  import IconSet from '@/components/IconSet.vue';

  export default {
    name: 'ImageSlider',
    components: {
      IconSet,
    },
    props: {
      images: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        current: 0,
        slider: null,
      };
    },
    computed: {
      isFirst() {
        return this.current === 0;
      },
      isLast() {
        return this.current === (this.images.length - 1);
      },
    },
    watch: {
      images() {
        if (this.$refs.slider) {
          this.create();
        }
      },
    },
    mounted() {
      this.create();
    },
    beforeDestroy() {
      if (this.slider) {
        this.slider.destroy();
      }
    },
    methods: {
      create() {
        if (this.slider) {
          this.slider.destroy();
        }

        this.slider = new KeenSlider(this.$refs.slider, {
          slides: '.slide',
          slideChanged: (slider) => {
            this.current = slider.details().relativeSlide;
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@gridonic/components/styles/button-plain";
  @import "~@/sass/meta";

  .image-slider {
    display: grid;
    grid-template-areas: "previous slider next" "dots dots dots";
    grid-template-columns: 24px 1fr 24px;
    grid-gap: 2space;

    > .slider {
      display: flex;
      grid-area: slider;
      overflow: hidden;
      position: relative;
      touch-action: pan-y;
      user-select: none;

      &[data-keen-slider-moves] * {
        pointer-events: none;
      }

      > .slide {
        background-size: cover;
        background-position: center center;
        overflow: hidden;
        position: relative;
        width: 100%;

        &::before {
          content: "";
          display: block;
          padding-top: 56.25%;
        }

        > .title {
          height: 24px;
          position: absolute;
          right: 5%;
          top: 5%;
          width: 24px;
        }

        > .selected {
          align-items: center;
          background-color: $sbh-palette-white;
          border: 2px solid currentColor;
          bottom: 5%;
          color: $sbh-palette-fir;
          display: flex;
          height: 25px;
          justify-content: center;
          line-height: 1;
          position: absolute;
          right: 5%;
          width: 25px;

          &::before {
            content: "✔";
            font-size: 15px;
          }
        }
      }
    }

    > .next,
    > .previous {
      @include gd-button-plain;

      align-self: center;
      border: 2px solid currentColor;
      color: $sbh-palette-fir;
      height: 28px;
      width: 28px;

      &.-disabled {
        border-color: $sbh-palette-timberwolf;
      }

      > .icon {
        height: 100%;
        width: 100%;
      }
    }

    > .next {
      grid-area: next;
    }

    > .previous {
      grid-area: previous;
      justify-self: flex-end;
    }

    > .dots {
      display: flex;
      grid-area: dots;
      justify-content: center;

      > .dot {
        @include gd-button-plain;

        align-items: center;
        border: 2px solid $sbh-palette-timberwolf;
        display: flex;
        height: 14px;
        justify-content: center;
        position: relative;
        width: 14px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &::before {
          background-color: $sbh-palette-fir;
          content: "";
          height: 6px;
          opacity: 0;
          transform: scale(0.6);
          transition: opacity $sbh-transition-duration, transform $sbh-transition-duration;
          width: 6px;
        }

        &.-active {
          border-color: $sbh-palette-fir;

          &::before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
</style>
