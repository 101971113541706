import ApiRepository from '@/core/api/ApiRepository';
import DonorSearchResult from '@/domain/donor/DonorSearchResult';

export default class DonorRepository extends ApiRepository {
  constructor(connector, converter) {
    super(connector);

    this.converter = converter;
  }

  /**
   * @param {TaskSearchFilter} donorSearchFilter
   *
   * @returns {Promise<DonorSearchResult>}
   */
  async readDonorsBySearchFilter(donorSearchFilter) {
    const result = await this.connector.create(
      'api_donor_search',
      donorSearchFilter.data,
    );

    return new DonorSearchResult(
      result.page,
      result.totalHits,
      result.totalPages,
      (result.donors || []).map((d) => this.convertDonor(d)),
    );
  }

  /**
   * @param {int} donorExternalSextantId
   *
   * @returns {Promise<Donor>}
   */
  async findDonorFromSextant(donorExternalSextantId) {
    const result = await this.connector.read(
      'api_donor_sextant_read', {
        donorExternalSextantId,
      },
    );

    return this.convertDonor(result.donor);
  }

  /**
   * @param {int} donorId
   *
   * @returns {Promise<Donor>}
   */
  async readDonor(donorId) {
    const result = await this.connector.read(
      'api_donor_read', {
        donorId,
      },
    );

    return this.convertDonor(result.donor);
  }

  /**
   * @private
   */
  convertDonor(responseData) {
    return this.converter.convertDonor(responseData);
  }
}
