<template>
  <div
    v-if="isEmpty === false"
    class="project-details-document-list"
  >
    <h2 class="title">
      {{ $t('label.documents') }}
    </h2>

    <div class="documents document-list">
      <a
        v-for="(document, index) in documents"
        :key="`document-${index}`"
        :href="document.url"
        class="document"
      >
        <span class="filename">
          {{ document.displayName }}
        </span>

        <span class="size">
          {{ document.fileSize|fileSize }}
        </span>

        <!-- seems we do not have that information (yet) -->
        <span class="uploader" />

        <span class="date">
          {{ document.changedAt|date }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
  import { format as date } from '@/domain/date/Date';

  export default {
    name: 'ProjectDetailsDocumentList',
    filters: {
      date,
      fileSize(bytes) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = 0;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
      },
    },
    props: {
      documents: {
        type: Array,
        default: () => ([]),
      },
    },
    computed: {
      isEmpty() {
        return Array.isArray(this.documents) === false || this.documents.length < 1;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-document-list {
    > .title {
      @include project-details-block-title;
    }
  }
</style>
