import SearchResult from '@/core/search/SearchResult';

export default class ProjectSearchResult extends SearchResult {
  constructor(page, totalHits, totalPages, specificProjectSearch, projects) {
    super(page, totalHits, totalPages, projects);

    this._specificProjectSearch = specificProjectSearch;
  }

  /**
   * @returns {Project[]}
   */
  get projects() {
    return this.items;
  }

  /**
   * @returns {boolean}
   */
  get specificProjectSearch() {
    return this.totalHits === 1 && this._specificProjectSearch;
  }
}
