import ApiRepository from '@/core/api/ApiRepository';

export default class ProjectSearchFilterRepository extends ApiRepository {
  /**
   * @param {ApiConnector} connector
   * @param {ProjectSearchFilterConverter} converter
   */
  constructor(connector, converter) {
    super(connector);

    this.converter = converter;
  }

  /**
   * @returns {Promise<ProjectSearchFilter>}
   */
  async readProjectSearchFilter() {
    const result = await this.connector.read(
      'api_project_filter_read',
    );
    return this.convertProjectSearchFilter(result);
  }

  /**
   * @returns {Promise<ProjectSearchFilter>}
   */
  async updateProjectSearchFilter(projectSearchFilter) {
    const result = await this.connector.create(
      'api_project_filter_create',
      projectSearchFilter,
    );
    return result;
  }

  /**
   * @private
   */
  convertProjectSearchFilter(responseData) {
    return this.converter.convertProjectSearchFilter(responseData.project_filter);
  }
}
