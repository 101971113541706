import ApiRepository from '@/core/api/ApiRepository';
import Favorite from '@/domain/user/Favorite';

export default class FavoriteRepository extends ApiRepository {
  constructor(connector, projectConverter) {
    super(connector);

    this.projectConverter = projectConverter;
  }

  async readFavorites() {
    const result = await this.connector.read('api_user_favorite_all');

    return this.convertFavorites(result.favorites);
  }

  async createFavorite(projectId) {
    const result = await this.connector.create('api_user_favorite_create', {
      projectId,
    });

    return this.convertFavorites(result.favorites);
  }

  async deleteFavorite(favoriteId) {
    const result = await this.connector.delete('api_user_favorite_delete', {
      favoriteId,
    });

    return this.convertFavorites(result.favorites);
  }

  async toggleFavoriteForProject(favorites, projectId) {
    const favorite = favorites.find((f) => f.projectId === projectId);

    return (favorite
      ? this.deleteFavorite(favorite.id) : this.createFavorite(projectId));
  }

  convertFavorites(data) {
    return data.map((f) => this.convertFavorite(f));
  }

  convertFavorite(data) {
    return new Favorite({
      ...data,
      project: this.projectConverter.convertProject(data.project ?? {}),
    });
  }
}
