<template>
  <div class="reservation-list">
    <h2 class="title">
      {{ $t('reservation.list.title') }}
    </h2>

    <div class="meta">
      <strong class="name">{{ name }}</strong>
      <span class="shortname">{{ shortName }}</span>
    </div>

    <table class="reservations">
      <thead class="head">
        <tr class="row">
          <th class="cell">
            {{ $t('reservation.list.name') }}
          </th>

          <th class="cell">
            {{ $t('reservation.list.id') }}
          </th>

          <th class="cell">
            {{ $t('reservation.list.status') }}
          </th>

          <th class="cell">
            {{ $t('reservation.list.amount') }}
          </th>

          <th class="cell">
            {{ $t('reservation.list.date') }}
          </th>

          <th class="cell">
            {{ $t('reservation.list.untilDate') }}
          </th>

          <th class="cell">
            {{ $t('reservation.list.manager') }}
          </th>
        </tr>
      </thead>
      <tbody class="body">
        <tr
          v-for="(reservation, index) in sortedReservations"
          :key="`reservation-${index}`"
          class="row"
        >
          <td class="cell">
            {{ reservation.donor ? reservation.donor.nameReversed : '?' }}
          </td>

          <td class="cell">
            {{ reservation.donor ? reservation.donor.externalId : '?' }}
          </td>

          <td class="cell">
            {{ reservation.status.name }}
          </td>

          <td class="cell">
            {{ reservation.amount|currency }}
          </td>

          <td class="cell">
            {{ reservation.valueDate|date }}
          </td>

          <td class="cell">
            {{ reservation.untilDate|date }}
          </td>

          <td class="cell">
            {{ reservation.manager ? reservation.manager.name : '?' }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="figures">
      <div class="total">
        <span>{{ $t('reservation.list.total') }} </span>
        <strong class="value">{{ reservedAmount|currency }}</strong>
      </div>

      <div class="outstanding">
        <span>{{ $t('reservation.list.outstanding') }} </span>
        <strong class="value">{{ outstandingBalance|currency }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
  import { format as date } from '@/domain/date/Date';
  import { format as currency } from '@/domain/currency/Currency';
  import { sortBy } from '@/core/helper/Sort';

  export default {
    name: 'ReservationList',
    filters: {
      currency,
      date,
    },
    props: {
      name: {
        type: String,
        default: null,
      },
      shortName: {
        type: String,
        default: null,
      },
      reservations: {
        type: Array,
        default: null,
      },
      outstandingBalance: {
        type: Number,
        default: null,
      },
      reservedAmount: {
        type: Number,
        default: null,
      },
    },
    computed: {
      sortedReservations() {
        return sortBy(this.reservations ?? [], 'valueDate');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .reservation-list {
    > .title {
      @include project-details-block-title;
    }

    > .meta,
    > .figures {
      display: flex;
    }

    > .meta {
      margin-top: 1.875space;

      > .name {
        font-weight: bold;
      }

      > .shortname {
        margin-left: 3space;
      }
    }

    > .reservations {
      border-bottom: 1px solid $palette-fir-20;
      border-spacing: 0;
      margin-bottom: 1.8125space;
      margin-top: 3space;
      padding-bottom: 1space;
      table-layout: fixed;

      > .head {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;

        > .row > .cell {
          border-bottom: 1px solid $palette-fir-20;
        }
      }

      > .body > .row > .cell {
        padding: 0.75space 0;
        font-size: 16px;
        line-height: 1.5;
      }

      > .head > .row > .cell,
      > .body > .row > .cell {
        &:not(:first-child) { padding-left: 1space; }
        &:not(:last-child) { padding-right: 1space; }
      }
    }

    > .figures {
      font-size: 16px;

      > .outstanding,
      > .total {
        display: flex;

        > .value {
          font-size: 21px;
          font-weight: bold;
          line-height: 1.14;
          padding-left: 1space;
        }
      }
      > .total {
        margin-right: 4space;
      }
    }
  }
</style>
