export default class ProjectFundingStatus {
  constructor(data) {
    this._id = data.id;
    this._name = data.name;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  /**
   * @param {ProjectFundingStatus|null} status
   *
   * @returns {boolean}
   */
  static isFundingStatusReadyInternalOrLater(status) {
    if (!status) {
      return false;
    }

    return status.id >= ProjectFundingStatus.Status.readyInternal;
  }

  static get Status() {
    return {
      available: 1002,
      readyInternal: 1007,
    };
  }
}
