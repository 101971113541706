<template>
  <div
    ref="map"
    class="map-view"
  />
</template>

<script>
  import GoogleMapsApiLoader from 'google-maps-api-loader';

  export default {
    name: 'MapView',
    props: {
      latitude: {
        type: Number,
        default: null,
      },
      longitude: {
        type: Number,
        default: null,
      },
      zoom: {
        type: Number,
        default: 11,
      },
    },
    data() {
      return {
        google: null,
        map: null,
        centerMarker: null,
      };
    },
    computed: {
      center() {
        return {
          lat: this.latitude,
          lng: this.longitude,
        };
      },
    },
    async mounted() {
      this.google = await GoogleMapsApiLoader({
        apiKey: 'AIzaSyAXKJYWqLqBcenF5Fh_Tl24fD9GMG0Wh00',
      });

      // @see https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
      this.map = new this.google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: this.zoom,
      });

      this.centerMarker = new this.google.maps.Marker({
        position: this.center,
        map: this.map,
      });
    },
  };
</script>

<style lang="scss" scoped>
  .map-view {
    min-height: 360px;
    width: 100%;
  }
</style>
