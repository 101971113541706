import ProjectFundingStatus from '@/domain/master-data/ProjectFundingStatus';

export const recommendedCharLimits = {
  title: 95,
  lead: 240,
  texts: 1700,
  acknowledgments: 400,
};

export default class Project {
  constructor(data) {
    this.data = data;
  }

  get id() {
    return this.data.id;
  }

  /**
   * @returns {int}
   */
  get sextantId() {
    return this.data.sextantId || null;
  }

  /**
   * @returns {string}
   */
  get shortName() {
    return this.data.shortName || null;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this.data.name || null;
  }

  /**
   * @returns {string}
   */
  get description() {
    return this.data.description || null;
  }

  /**
   * @returns {ProjectListStatus}
   */
  get status() {
    return this.data.fundingStatus || null;
  }

  /**
   * This is NOT the funding status which is mostly used in this project, but the
   * general project status!
   *
   * @returns {ProjectStatus}
   */
  get projectStatus() {
    return this.data.projectStatus || null;
  }

  /**
   * @returns {ProjectArea}
   */
  get area() {
    return this.data.area || null;
  }

  /**
   * @return {string[]}
   */
  get languages() {
    return this.data.languages || [];
  }

  /**
   * @returns {Canton}
   */
  get canton() {
    return this.data.canton || null;
  }

  /**
   * @returns {Region}
   */
  get region() {
    return this.data.region || null;
  }

  /**
   * @returns {Coordinates}
   */
  get coordinates() {
    return this.data.coordinates || null;
  }

  /**
   * @returns {string}
   */
  get street() {
    return this.data.street || null;
  }

  /**
   * @returns {string}
   */
  get zip() {
    return this.data.zip || null;
  }

  /**
   * @returns {string}
   */
  get place() {
    return this.data.place || null;
  }

  /**
   * @returns {string}
   */
  get mountainZone() {
    return this.data.mountainZone || null;
  }

  /**
   * @returns {boolean}
   */
  get hasChildren() {
    return (this.data.childrenCount || 0) > 0;
  }

  /**
   * @returns {boolean}
   */
  get areYoungApplicants() {
    const age = this.data.applicantAge || 0;
    return age > 0 && age <= 35;
  }

  /**
   * @returns {boolean}
   */
  get usagesInLists() {
    return this.data.usagesInLists || 0;
  }

  /**
   * @returns {Date}
   */
  get decisionDate() {
    return this.data.decisionDate;
  }

  /**
   * @returns {Date|null}
   */
  get readyInternalAt() {
    return this.data.readyInternalAt;
  }

  /**
   * @returns {ProjectImage[]}
   */
  get images() {
    return this.data.images ?? [];
  }

  /**
   * @returns {int}
   */
  get imageCount() {
    return this.images.length;
  }

  /**
   * @returns {boolean}
   */
  get hasImages() {
    return this.imageCount > 0;
  }

  /**
   * @returns {ProjectDocument[]}
   */
  get documents() {
    return this.data.documents ?? [];
  }

  /**
   * @returns {{string}}
   */
  get localizedTitles() {
    return this.data.localizedTitles || {};
  }

  /**
   * @returns {{string}}
   */
  get localizedSubtitles() {
    return this.data.localizedSubtitles || {};
  }

  /**
   * @returns {{string}}
   */
  get localizedLeads() {
    return this.data.localizedLeads || {};
  }

  /**
   * @returns {{string}}
   */
  get localizedTexts() {
    return this.data.localizedTexts || {};
  }

  /**
   * @returns {{string}}
   */
  get localizedAcknowledgments() {
    return this.data.localizedAcknowledgments || {};
  }

  /**
   * @returns {string}
   */
  get notes() {
    return this.data.notes || null;
  }

  /**
   * @returns {ProjectFinancePlan}
   */
  get financePlan() {
    return this.data.financePlan || {};
  }

  /**
   * @returns {Reservation[]}
   */
  get reservations() {
    return this.data.reservations || [];
  }

  /**
   * @returns {Partner}
   */
  get applicant() {
    return this.data.applicant || null;
  }

  /**
   * @returns {Partner}
   */
  get expert() {
    return this.data.expert || null;
  }

  /**
   * @returns {Partner}
   */
  get copywriter() {
    return this.data.copywriter || null;
  }

  /**
   * @returns {Partner}
   */
  get translator() {
    return this.data.translator || null;
  }

  /**
   * @returns {ProjectImage|null}
   */
  get titleOrFallbackImage() {
    const titleImage = this.images.find((i) => i.isTitleImage);

    if (titleImage) {
      return titleImage;
    }

    return this.data.fallbackImage;
  }

  /**
   * @returns {string}
   */
  get externalTitle() {
    return this.getLocalizedTitle('de')
      || this.getLocalizedTitle('fr')
      || '';
  }

  /**
   * @param {'de'|'fr'} locale
   *
   * @returns {string}
   */
  getLocalizedTitle(locale) {
    return this.localizedTitles[locale] || null;
  }

  /**
   * @param {'de'|'fr'} locale
   *
   * @returns {string}
   */
  getLocalizedSubtitle(locale) {
    return this.localizedSubtitles[locale] || null;
  }

  /**
   * @param {'de'|'fr'} locale
   *
   * @returns {string}
   */
  getLocalizedLead(locale) {
    return this.localizedLeads[locale] || null;
  }

  /**
   * @param {'de'|'fr'} locale
   *
   * @returns {string}
   */
  getLocalizedTextAsHtml(locale) {
    return this.localizedTexts[locale] || null;
  }

  /**
   * @param {'de'|'fr'} locale
   *
   * @returns {string}
   */
  getLocalizedAcknowledgments(locale) {
    return this.localizedAcknowledgments[locale] || null;
  }

  /**
   * @param {Donor} donor
   *
   * @returns {Reservation}
   */
  reservationOfDonor(donor) {
    if (!donor) {
      return null;
    }

    return this.reservations
      .find((r) => r.donor && r.donor.id === donor.id) ?? null;
  }

  /**
   * @param {ProjectFinancePlan} financePlan
   * @return {boolean}
   */
  isApprovedButIncompletelyPayed(financePlan) {
    if (!this.status || !financePlan) {
      return false;
    }

    if (this.status.id !== ProjectFundingStatus.Status.available) {
      return false;
    }

    return financePlan.paidAmount < financePlan.plannedAmount;
  }
}
