import Partner from '@/domain/user/Partner';

export default class User extends Partner {
  constructor(data) {
    super(data);

    this._initialActiveProjectListId = data.initialActiveProjectListId || null;
  }

  /**
   * @returns {int}
   */
  get initialActiveProjectListId() {
    return this._initialActiveProjectListId;
  }
}
