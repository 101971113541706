import ProjectSearchFilter from './ProjectSearchFilter';
import ProjectArea from '../master-data/ProjectArea';
import Canton from '../master-data/Canton';
import ProjectStatus from '../master-data/ProjectStatus';
import Region from '../master-data/Region';

export default class ProjectSearchFilterConverter {
  constructor(masterData) {
    this.masterData = masterData;
  }

  /* eslint-disable max-len */
  convertProjectSearchFilter(data) {
    const arrayValueOrNull = (v) => (v && v.length > 0 ? v : null);

    const filter = new ProjectSearchFilter();
    if (!data) {
      return filter;
    }

    filter._areas = data._areas ? this.convertProjectAreas(data._areas) : null;
    filter._cantons = data._cantons ? this.convertCantons(data._cantons) : null;
    filter._decisionDateRange = data._decisionDateRange ? this.convertDateRange(data._decisionDateRange) : null;
    filter._modifiedDateRange = data._modifiedDateRange ? this.convertDateRange(data._modifiedDateRange) : null;
    filter._favoritesOnly = data._favoritesOnly || null;
    filter._hasImages = data._hasImages || null;
    filter._involvesChildren = data._involvesChildren || null;
    filter._languages = arrayValueOrNull(data._languages);
    filter._mountainZones = arrayValueOrNull(data._mountainZones);
    filter._outstandingBalanceRange = arrayValueOrNull(data._outstandingBalanceRange);
    filter._place = data._place || null;
    filter._regions = data._regions ? this.convertRegions(data._regions) : null;
    filter._searchQuery = data._searchQuery || null;
    filter._sortBy = data._sortBy || null;
    filter._sortDirection = data._sortDirection || null;
    filter._status = data._status ? this.convertProjectStatus(data._status) : null;
    filter._usagesInLists = arrayValueOrNull(data._usagesInLists);
    filter._youngApplicantsOnly = data._youngApplicantsOnly || null;

    return filter;
  }

  convertProjectAreas(areas) {
    return areas.map((area) => new ProjectArea({ id: area._id, name: area._name }));
  }

  convertCantons(cantons) {
    return cantons.map((canton) => new Canton({ id: canton._id, name: canton._name }));
  }

  convertRegions(regions) {
    return regions.map((region) => new Region({ id: region._id, name: region._name }));
  }

  convertProjectStatus(states) {
    return states.map((status) => new ProjectStatus({ id: status._id, name: status._name }));
  }

  convertDateRange(range) {
    return range.map((date) => new Date(date));
  }
}
