import Partner from '@/domain/user/Partner';

export default class Donor extends Partner {
  constructor(data) {
    super(data);

    this._manager = this.data.manager || null;
    this._contactPerson = this.data.contactPerson || null;
    this._reservations = this.data.reservations || [];
    this._lastUsageInList = this.data.lastUsageInList || null;
  }

  /**
   * @returns {Partner}
   */
  get manager() {
    return this._manager;
  }

  /**
   * @returns {Partner}
   */
  get contactPerson() {
    return this._contactPerson;
  }

  /**
   * @returns {Reservation[]}
   */
  get reservations() {
    return this._reservations;
  }

  /**
   * @returns {Date}
   */
  get lastUsageInList() {
    return this._lastUsageInList;
  }

  /**
   * @returns {number}
   */
  get totalReservedAmount() {
    return this.reservations
      .filter((r) => !r.readonly)
      .reduce((sum, r) => sum + r.amount, 0);
  }
}
