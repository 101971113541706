import ApiRepository from '@/core/api/ApiRepository';

import ProjectSearchResult from '@/domain/project/ProjectSearchResult';

export default class ProjectRepository extends ApiRepository {
  /**
   * @param {ApiConnector} connector
   * @param {ProjectConverter} converter
   */
  constructor(connector, converter) {
    super(connector);

    this.converter = converter;
  }

  /**
   * @param {ProjectSearchFilter} projectSearchFilter
   *
   * @returns {Promise<ProjectSearchResult>}
   */
  async readProjectsBySearchFilter(projectSearchFilter) {
    const result = await this.connector.create(
      'api_project_search',
      projectSearchFilter.data,
    );

    return new ProjectSearchResult(
      result.page,
      result.totalHits,
      result.totalPages,
      result.specificProjectSearch,
      (result.projects || []).map((p) => this.convertProject(p)),
    );
  }

  /**
   * @param {int} projectId
   *
   * @returns {Promise<Project>}
   */
  async readProject(projectId) {
    const result = await this.connector.read(
      'api_project_read', {
        projectId,
      },
    );

    return this.convertProject(result.project);
  }

  /**
   * @param {int} projectId
   *
   * @returns {Promise<Reservation[]>}
   */
  async readProjectReservations(projectId) {
    const result = await this.connector.read(
      'api_project_reservation_all', {
        projectId,
      },
    );

    return result.reservations.map((r) => this.convertReservation(r));
  }

  /**
   * @returns {Promise<Project>}
   */
  async updateProject(
    projectId,
    {
      statusId,
      translatorId,
      copywriterId,
      localizedTitles,
      localizedLeads,
      localizedAcknowledgments,
      localizedTextPmModels,
      titleImageId,
      selectedImageIds,
      notes,
      readyInternalAt,
    },
  ) {
    const result = await this.connector.update(
      'api_project_update', {
        statusId,
        translatorId,
        copywriterId,
        localizedTitles,
        localizedLeads,
        localizedAcknowledgments,
        localizedTexts: localizedTextPmModels,
        titleImageId,
        selectedImageIds,
        notes,
        readyInternalAt: readyInternalAt ? readyInternalAt.getTime() / 1000 : null,
      },
      {
        projectId,
      },
    );
    return this.convertProject(result.project);
  }

  /**
   * @private
   */
  convertProject(responseData) {
    return this.converter.convertProject(responseData);
  }

  /**
   * @private
   */
  convertReservation(responseData) {
    return this.converter.convertReservation(responseData);
  }
}
