<template>
  <div
    v-if="isEmpty === false"
    class="project-details-map"
  >
    <h2 class="title">
      {{ $t('label.location') }}
    </h2>

    <map-view
      v-if="coordinates"
      :latitude="coordinates.latitude"
      :longitude="coordinates.longitude"
    />

    <span
      v-if="name"
      class="name"
    >
      {{ name }}
    </span>

    <span v-if="street">{{ street }}, </span>
    <span v-if="zip">{{ zip }}</span>
    <span v-if="city">{{ city }}</span>
    <span v-if="canton"> ({{ canton.name }})</span>
  </div>
</template>

<script>
  import MapView from '@/components/MapView.vue';

  export default {
    name: 'ProjectDetailsMap',
    components: {
      MapView,
    },
    props: {
      coordinates: {
        type: Object,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      street: {
        type: String,
        default: null,
      },
      zip: {
        type: String,
        default: null,
      },
      city: {
        type: String,
        default: null,
      },
      canton: {
        type: Object,
        default: null,
      },
    },
    computed: {
      isEmpty() {
        return this.coordinates === null
          || this.coordinates === undefined
          || typeof this.coordinates.longitude !== 'number'
          || typeof this.coordinates.latitude !== 'number';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-map {
    > .title {
      @include project-details-block-title;
    }

    > .name {
      display: block;
      font-weight: bold;
      margin-bottom: 0.25space;
      margin-top: 2space;
    }
  }
</style>
