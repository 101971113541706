<template>
  <div class="list-header">
    <template
      v-for="(column, index) in headerColumns"
    >
      <div
        v-if="column.sortBy || column.label"
        :key="`sort-${index}`"
        class="item"
        :class="columnClasses(column)"
      >
        <button
          class="column-sorter"
          :class="columnClasses(column)"
          @click="triggerChange(column)"
        >
          {{ $t(column.label) }}
        </button>
      </div>
      <span
        v-else
        :key="`sort-${index}`"
      />  <!-- empty columns -->
    </template>
  </div>
</template>

<script>
  import { SortDirection } from '@/core/search/SearchFilter';

  export default {
    name: 'ListHeader',
    props: {
      headerColumns: {
        type: Array,
        default: null,
      },
      sortBy: {
        type: String,
        default: null,
      },
      sortDirection: {
        type: String,
        default: null,
      },
    },
    methods: {
      triggerChange(column) {
        if (!column.sortBy) {
          return;
        }

        const sort = {
          sortBy: column.sortBy,
          sortDirection: SortDirection.ASC,
        };

        if (this.sortBy === column.sortBy) {
          sort.sortDirection = this.sortDirection === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC;
        }

        this.$emit('change', sort);
      },
      columnClasses(column) {
        const classes = ['column'];

        if (!column.sortBy) {
          classes.push('-nosort');
        }

        if (column.align) {
          classes.push(`-${column.align}`);
        }

        if (column.sortBy !== this.sortBy) {
          return classes;
        }

        if (this.sortDirection === SortDirection.ASC) {
          classes.push('-asc');
        } else if (this.sortDirection === SortDirection.DESC) {
          classes.push('-desc');
        }

        return classes;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@gridonic/components/styles/button-plain";
  @import "~@/sass/meta";

  .list-header {
    color: $sbh-palette-white;
    display: grid;
    grid-gap: 1space;
    align-items: center;
    padding: 0 3space 0 2space;

    > .item {
      &.-center {
        text-align: center;
      }

      &.-right {
        text-align: right;
      }
    }
  }
</style>
