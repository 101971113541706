<template>
  <div class="form-date">
    <span
      v-if="inline"
      ref="picker"
      class="picker"
    />

    <input
      v-else
      ref="picker"
      class="input"
      type="date"
      readonly
    >
  </div>
</template>

<script>
  import flatpickr from 'flatpickr';

  export default {
    name: 'FormDate',
    model: {
      prop: 'value',
      event: 'change',
    },
    props: {
      mode: {
        type: String,
        default: 'range',
      },
      inline: {
        type: Boolean,
        default: true,
      },
      value: {
        type: [Date, String, Array],
        default: null,
      },
    },
    data() {
      return {
        picker: null,
        localState: null,
      };
    },
    mounted() {
      this.picker = flatpickr(this.$refs.picker, {
        defaultDate: this.value,
        mode: this.mode,
        inline: this.inline,
        dateFormat: 'd.m.Y',
        showMonths: this.mode === 'range' ? 2 : 1,
        onChange: this.onChange.bind(this),
        onReady: () => this.$emit('ready'),
      });
    },
    beforeDestroy() {
      this.picker.destroy();
    },
    methods: {
      onChange(selectedDates) {
        this.$emit(
          'change',
          this.localState = this.mode === 'range' ? selectedDates : selectedDates[0],
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .form-date {
    position: relative;
    border-bottom: 2px solid $sbh-palette-timberwolf;

    &.-inverted {
      > .input {
        color: $sbh-palette-white;
      }
    }

    > .picker {
      position: absolute;
      visibility: hidden;
    }

    > .input {
      background-color: transparent;
      border: 0;
      font-family: inherit;
      font-size: 18px;
      line-height: 1.56;
      padding: 0.25space 0.5space;
      width: 100%;
    }
  }
</style>
