<template>
  <div
    class="donor-detail"
  >
    <h2 class="title">
      {{ $t('donor.detail.title') }}
    </h2>

    <donor-info
      :donor="donor"
      class="donorinfo"
    />

    <div class="projectlists">
      <div class="title">
        {{ $t('donor.detail.projectLists.title') }}
      </div>

      <button
        type="button"
        class="listlink button-link"
        @click.prevent.stop="$emit('create-project-list', donor)"
      >
        <icon-set icon="add" />{{ $t('label.projectList') }}
      </button>

      <is-loading
        #wait="{ result: projectLists }"
        :request="projectListsPromise"
        :label="$t('label.isLoading')"
        :error-label="$t('label.loadError')"
      >
        <table class="table-default">
          <thead>
            <tr>
              <th>{{ $t('donor.detail.projectLists.updatedAt') }}</th>
              <th>{{ $t('donor.detail.projectLists.count') }}</th>
              <th>{{ $t('donor.detail.projectLists.reservedAmount') }}</th>
              <th>{{ $t('donor.detail.projectLists.status') }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in projectLists"
              :key="`reservation-${index}`"
              class="reservation"
            >
              <td><b>{{ list.updatedAt|date }}</b></td>
              <td>{{ list.projects.length }}</td>
              <td><b>{{ list.totalReservedAmount }}</b></td>
              <td>{{ list.status ? list.status.name : '' }}</td>
              <td>
                <router-link
                  :to="{ name: 'projectlist', params: { listId: list.id }}"
                  class="action"
                >
                  <icon-set icon="arrow-right" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </is-loading>
    </div>

    <div class="reservations">
      <h3 class="title">
        {{ $t('donor.detail.reservations.title') }}
      </h3>

      <is-loading
        #wait="{ result: reservations }"
        :request="reservationsPromise"
        :label="$t('label.isLoading')"
        :error-label="$t('label.loadError')"
      >
        <table class="table-default">
          <thead>
            <tr>
              <th />
              <th>{{ $t('donor.detail.reservations.number') }}</th>
              <th>{{ $t('donor.detail.reservations.region') }}</th>
              <th>{{ $t('donor.detail.reservations.reservedAmount') }}</th>
              <th>{{ $t('donor.detail.reservations.untilDate') }}</th>
              <th>{{ $t('donor.detail.reservations.status') }}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(reservation, index) in sortedReservations(reservations)"
              :key="`reservation-${index}`"
              class="reservation"
            >
              <td><b>{{ reservation.project.name }}</b></td>
              <td>{{ reservation.project.shortName }}</td>
              <td class="region">
                {{ region(reservation.project.region) }}
              </td>
              <td
                class="amount"
                @click.prevent.stop="$emit('reservation-selected', reservation)"
              >
                <b>{{ reservation.amount }}</b>
              </td>
              <td>{{ reservation.untilDate|date }}</td>
              <td>{{ reservation.status.name }}</td>
              <td v-if="reservation.project && reservation.project.id">
                <router-link
                  :to="{ name: 'project', params: { projectId: reservation.project.id }}"
                  class="action"
                >
                  <icon-set icon="arrow-right" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </is-loading>
    </div>
  </div>
</template>

<script>
  import { format as date } from '@/domain/date/Date';

  import DonorInfo from '@/components/donor/DonorInfo.vue';
  import IconSet from '@/components/IconSet.vue';
  import IsLoading from '@/components/IsLoading.vue';

  import Donor from '@/domain/donor/Donor';
  import { sortBy } from '@/core/helper/Sort';

  export default {
    name: 'DonorDetail',
    components: { IsLoading, IconSet, DonorInfo },
    inject: [
      'repositories',
    ],
    filters: {
      date,
    },
    props: {
      donor: {
        type: Donor,
        required: true,
      },
      projectListsPromise: {
        type: Promise,
        default: null,
      },
      reservationsPromise: {
        type: Promise,
        default: null,
      },
    },
    computed: {
      masterDataRepository() {
        return this.repositories.masterData;
      },
    },
    methods: {
      sortedReservations(reservations) {
        return sortBy(reservations ?? [], 'valueDate');
      },
      region(value) {
        const found = this.masterDataRepository.getRegion(value);
        return found ? found.name : null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";
  @import "~include-media/dist/include-media";

  .donor-detail {
    background-color: $sbh-palette-white;

    > .title {
      @include project-details-block-title;

      margin-bottom: 2space;
    }

    > .donorinfo {
      margin-bottom: 3space;
    }

    > .projectlists,
    > .reservations {
      margin: 2space 0;
      padding-left: 1.75space;
      padding-right: 1.75space;

      > .title {
        @include project-details-block-title;
      }

      td > .action {
        float: right;

        > .icon-set {
          color: $palette-sun-initial;
          width: 30px;
          height: 30px;
        }
      }

      td.region {
        max-width: 160px;
      }

      td.amount {
          cursor: pointer;
      }

      @include media("<=lg") {
        th:not(:first-child),
        td:not(:first-child) {
          padding-left: 0.5rem;
        }
      }
    }

    > .projectlists {
      position: relative;

      > .listlink {
        position: absolute;
        top: 0;
        right: 1.75space;
      }
    }
  }
</style>
