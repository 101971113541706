<template>
  <label class="form-toggle">
    <input
      class="input"
      type="checkbox"
      :checked="checked"
      @change="triggerChange"
    >
    <span class="no">{{ $t('label.no') }}</span>
    <span class="toggle" />
    <span class="yes">{{ $t('label.yes') }}</span>
  </label>
</template>

<script>
  export default {
    name: 'FormToggle',
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      checked: {
        type: Boolean,
        default: false,
      },

    },
    methods: {
      triggerChange({ target: { checked } }) {
        this.$emit('change', checked);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
