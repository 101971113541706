import ApiRepository from '@/core/api/ApiRepository';

import User from '@/domain/user/User';

export default class UserRepository extends ApiRepository {
  constructor(connector, data) {
    super(connector);

    this._user = this.convertUser(data.user ?? {});
    this._impersonation = data.impersonation || {};
  }

  /**
   * @return {boolean}
   */
  get isImpersonation() {
    return this._impersonation.enabled || false;
  }

  /**
   * @returns {User}
   */
  get current() {
    return this._user;
  }

  /**
   * @returns {Promise<User[]>}
   */
  async readFundingAdvisors() {
    const result = await this.connector.read('api_user_funding_advisors_all', {});

    return result.users.map((u) => this.convertUser(u));
  }

  convertUser(data) {
    return new User(data);
  }
}
