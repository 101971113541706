export default class SearchResult {
  constructor(page, totalHits, totalPages, items) {
    this._page = page;
    this._totalHits = totalHits;
    this._totalPages = totalPages;
    this._items = items;
  }

  /**
   * @return {int}
   */
  get page() {
    return this._page;
  }

  /**
   * @return {int}
   */
  get totalHits() {
    return this._totalHits;
  }

  /**
   * @return {int}
   */
  get totalPages() {
    return this._totalPages;
  }

  /**
   * @returns {*}
   */
  get items() {
    return this._items;
  }
}
