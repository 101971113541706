import SearchFilter from '@/core/search/SearchFilter';

export const SortProperty = Object.freeze({
  PROJECT_AREA: 'area',
  SHORT_NAME: 'shortName',
  CANTON: 'canton',
  OUTSTANDING_BALANCE: 'outstandingBalance',
  STATUS: 'projectFundingStatus',
  READY_INTERNAL_AT: 'readyInternalAtTimestamp',
});

export default class ProjectSearchFilter extends SearchFilter {
  constructor() {
    super();

    this._searchQuery = null;

    this._status = null;
    this._languages = null;

    this._areas = null;
    this._cantons = null;
    this._mountainZones = null;
    this._place = null;
    this._regions = [];

    this._decisionDateRange = null;
    this._modifiedDateRange = null;
    this._outstandingBalanceRange = null;
    this._usagesInLists = null;
    this._favoritesOnly = false;

    this._involvesChildren = false;
    this._youngApplicantsOnly = false;

    this._hasImages = false;
  }

  /**
   * @returns {string}
   */
  get searchQuery() {
    return this._searchQuery || null;
  }

  set searchQuery(value) {
    this._searchQuery = value;
  }

  /**
   * @returns {[]}
   */
  get status() {
    return this._status || [];
  }

  set status(value) {
    this._status = value;
  }

  /**
   * @returns {[]}
   */
  get languages() {
    return this._languages || [];
  }

  set languages(value) {
    this._languages = value;
  }

  /**
   * @returns {ProjectArea[]}
   */
  get areas() {
    return this._areas || [];
  }

  set areas(value) {
    this._areas = value;
  }

  /**
   * @returns {Canton[]}
   */
  get cantons() {
    return this._cantons || [];
  }

  set cantons(value) {
    this._cantons = value;
  }

  /**
   * @returns {MountainZone[]}
   */
  get mountainZones() {
    return this._mountainZones || [];
  }

  set mountainZones(value) {
    this._mountainZones = value;
  }

  /**
   * @returns {string}
   */
  get place() {
    return this._place;
  }

  set place(value) {
    this._place = value;
  }

  /**
   * @returns {string}
   */
  get regions() {
    return this._regions || [];
  }

  set regions(value) {
    this._regions = value;
  }

  /**
   * @returns {Date[]}
   */
  get decisionDateRange() {
    return this._decisionDateRange || [];
  }

  set decisionDateRange(value) {
    this._decisionDateRange = value;
  }

  /**
   * @returns {Date[]}
   */
  get modifiedDateRange() {
    return this._modifiedDateRange || [];
  }

  set modifiedDateRange(value) {
    this._modifiedDateRange = value;
  }

  /**
   * @returns {int[]}
   */
  get outstandingBalanceRange() {
    return this._outstandingBalanceRange || [];
  }

  set outstandingBalanceRange(value) {
    this._outstandingBalanceRange = value;
  }

  /**
   * @returns {int[]}
   */
  get usagesInLists() {
    return this._usagesInLists || [];
  }

  set usagesInLists(value) {
    this._usagesInLists = value;
  }

  /**
   * @returns {boolean}
   */
  get favoritesOnly() {
    return this._favoritesOnly;
  }

  set favoritesOnly(value) {
    this._favoritesOnly = value;
  }

  /**
   * @returns {boolean}
   */
  get involvesChildren() {
    return this._involvesChildren;
  }

  set involvesChildren(value) {
    this._involvesChildren = value;
  }

  /**
   * @returns {boolean}
   */
  get youngApplicantsOnly() {
    return this._youngApplicantsOnly;
  }

  set youngApplicantsOnly(value) {
    this._youngApplicantsOnly = value;
  }

  /**
   * @returns {boolean}
   */
  get hasImages() {
    return this._hasImages;
  }

  set hasImages(value) {
    this._hasImages = value;
  }

  /**
   * @return {object}
   */
  get data() {
    return {
      page: this.page,
      hitsPerPage: this.hitsPerPage,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      searchQuery: this.searchQuery,
      status: this.status.map((s) => s.id),
      languages: (this.languages || []).map(({ code }) => code),
      areas: this.areas.map((a) => a.id),
      cantons: this.cantons.map((c) => c.id),
      mountainZones: this.mountainZones.map((m) => m.id),
      place: this.place,
      regions: this.regions.map((r) => r.id),
      decisionDateRange: (this.decisionDateRange || []).map((d) => d.getTime() / 1000),
      modifiedDateRange: (this.modifiedDateRange || []).map((d) => d.getTime() / 1000),
      outstandingBalanceRange: this.outstandingBalanceRange,
      usagesInLists: this.usagesInLists,
      favoritesOnly: this.favoritesOnly,
      involvesChildren: this.involvesChildren,
      youngApplicantsOnly: this.youngApplicantsOnly,
      hasImages: this.hasImages,
    };
  }
}
