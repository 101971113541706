export default class Task {
  constructor(data) {
    this._project = data.project;
    this._deadline = data.deadline ?? null;
  }

  /**
   * @return {int}
   */
  get projectId() {
    return this._project ? this._project.id : null;
  }

  /**
   * @returns {string}
   */
  get name() {
    return this._project ? this._project.name : null;
  }

  /**
   * @returns {string}
   */
  get number() {
    return this._project ? this._project.shortName : null;
  }

  /**
   * @returns {ProjectFundingStatus}
   */
  get status() {
    return this._project ? this._project.status : null;
  }

  /**
   * @returns {Date}
   */
  get deadline() {
    return this._deadline;
  }
}
