<template>
  <div class="translation-panel">
    <div class="title">
      {{ $t('translationsPanel.title') }}
    </div>

    <div class="actions">
      <button
        type="button"
        class="button-primary apply"
        @click="apply"
      >
        {{ $t('translationsPanel.apply') }}
      </button>

      <button
        type="button"
        class="cancel"
        @click="cancel"
      >
        {{ $t('translationsPanel.cancel') }}
      </button>
    </div>

    <div class="translations">
      <div
        v-for="(locale, index) in ['de', 'fr']"
        :key="`locale-${index}`"
        :class="{ translation: true, '-locked': language === locale }"
      >
        <div class="language">
          <span class="label">{{ $t(`label.${locale}`) }}</span>

          <icon-set
            v-if="language === locale"
            icon="lock-closed"
          />
        </div>

        <content-editor
          class="title h3"
          :editable="language !== locale"
          :formattable="false"
          :content="titles[locale]"
          :placeholder="$t('details.copy.titles')"
          :recommended-char-limit="recommendedCharLimits.title"
          @change="onChange('localizedTitles', $event.json, locale)"
        />

        <content-editor
          class="lead"
          :editable="language !== locale"
          :formattable="false"
          :content="leads[locale]"
          :placeholder="$t('details.copy.leads')"
          :recommended-char-limit="recommendedCharLimits.lead"
          @change="onChange('localizedLeads', $event.json, locale)"
        />

        <content-editor
          class="text"
          :editable="language !== locale"
          :content="texts[locale]"
          :placeholder="$t('details.copy.texts')"
          :recommended-char-limit="recommendedCharLimits.texts"
          @change="onChange('localizedTextPmModels', $event.json, locale)"
        />

        <content-editor
          class="acknowledgments"
          :editable="language !== locale"
          :formattable="false"
          :content="acknowledgments[locale]"
          :placeholder="$t('details.copy.acknowledgments')"
          :recommended-char-limit="recommendedCharLimits.acknowledgments"
          @change="onChange('localizedAcknowledgments', $event.json, locale)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { supported } from '@/domain/master-data/Language';

  import ContentEditor from '@/components/ContentEditor.vue';
  import IconSet from '@/components/IconSet.vue';

  import { recommendedCharLimits } from '@/domain/project/Project';

  export default {
    name: 'TranslationPanel',
    components: {
      ContentEditor,
      IconSet,
    },
    props: {
      language: {
        type: String,
        default: null,
      },
      leads: {
        type: Object,
        default: null,
      },
      texts: {
        type: Object,
        default: null,
      },
      titles: {
        type: Object,
        default: null,
      },
      acknowledgments: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        supported,
        localState: {},
      };
    },
    computed: {
      recommendedCharLimits: () => recommendedCharLimits,
    },
    methods: {
      onChange(key, value, locale) {
        this.$set(this.localState, 'locale', locale);
        this.$set(this.localState, key, value);
      },
      apply() {
        this.$emit('apply', this.localState);
      },
      cancel() {
        this.$emit('cancel');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@gridonic/components/styles/button-plain";
  @import "~@/sass/meta";

  .translation-panel {
    > .title {
      @include project-details-block-title;
    }

    > .actions {
      margin-top: 2.5space;

      > .cancel {
        @include gd-button-plain;

        color: $sbh-palette-nickel;
        margin-left: 2space;
        text-decoration: underline;
      }
    }

    > .translations {
      display: grid;
      grid-column-gap: 2space;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 2.875space;

      > .translation {
        &.-locked {
          cursor: not-allowed;
          opacity: 0.5;

          > .language {
            align-items: center;
            box-shadow: inset 0 -1px 0 $sbh-palette-black;
            color: $sbh-palette-black;
            display: flex;
            justify-content: space-between;

            > .label {
              background-color: rgba($sbh-palette-black, 0.2);
            }
          }

          > .title {
            color: $sbh-palette-black;
          }
        }

        > .title {
          color: $sbh-palette-fir;
          margin-bottom: 2space;

          ::v-deep p {
            font-weight: 700;
          }
        }

        > .lead {
          margin-bottom: 2.625space;
        }

        > .language {
          box-shadow: inset 0 -1px 0 rgba($sbh-palette-fir, 0.2);
          color: $sbh-palette-fir;
          display: flex;
          margin-bottom: 1.625space;

          > .label {
            background-color: rgba($sbh-palette-fir, 0.2);
            content: attr(title);
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 1.29;
            padding: 0.25space 0.5space;
            text-transform: uppercase;
          }
        }

        > .acknowledgments {
          border-top: 2px solid $sbh-palette-timberwolf;
          margin-top: 3space;
          padding-top: 1space;
        }
      }
    }
  }
</style>
