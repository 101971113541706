<template>
  <div
    v-if="isEmpty === false"
    class="project-details-gallery"
  >
    <h2 class="title">
      {{ $t('label.gallery') }}
    </h2>

    <image-slider
      v-if="editMode === false"
      :images="images"
    />

    <div
      v-else
      class="gallery"
    >
      <div class="images">
        <div
          v-for="(image, index) in images"
          :key="`image-${index}`"
          :class="{ image: true, '-title': isTitleImage(image.sextantId) }"
          :style="{ backgroundImage: `url(${image.url})` }"
          @click.self="setTitleImage(image.sextantId)"
        >
          <icon-set
            v-if="isTitleImage(image)"
            icon="active"
            class="title"
            @click.native.prevent.stop="setTitleImage(null)"
          />

          <icon-set
            icon="trash"
            class="trash"
          />

          <label class="selection">
            <input
              v-model="selectedImageIds"
              type="checkbox"
              :value="image.sextantId"
              class="input"
              @click="onClick($event, image)"
              @change="triggerChange"
            >
            <span class="trigger" />
          </label>
        </div>
      </div>

      <div class="title">
        <icon-set icon="active" /> {{ $t('details.gallery.titleImage') }}
      </div>

      <div class="amount">
        {{ $t('details.gallery.amount', { count: selectedImageIds.length }) }}
      </div>
    </div>
  </div>
</template>

<script>
  import ImageSlider from '@/components/ImageSlider.vue';
  import IconSet from '@/components/IconSet.vue';

  export default {
    name: 'ProjectDetailsGallery',
    components: {
      ImageSlider,
      IconSet,
    },
    props: {
      images: {
        type: Array,
        default: () => ([]),
      },
      editMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedImageIds: this
          .images
          .filter((image) => image.isSelected)
          .map(({ sextantId }) => sextantId),
        titleImageId: (this
          .images
          .find((image) => image.isTitleImage) || { sextantId: null }).sextantId,
      };
    },
    computed: {
      isEmpty() {
        return Array.isArray(this.images) === false || this.images.length < 1;
      },
    },
    methods: {
      onClick(e, image) {
        if (this.titleImageId === image.sextantId) {
          this.setTitleImage(null);
        }
      },
      triggerChange() {
        this.$emit('change', {
          titleImageId: this.titleImageId,
          selectedImageIds: this.selectedImageIds,
        });
      },
      setTitleImage(id) {
        this.titleImageId = this.titleImageId === id ? null : id;

        if (this.selectedImageIds.indexOf(id) < 0) {
          this.selectedImageIds.push(id);
        }

        this.triggerChange();
      },
      isTitleImage(image) {
        return this.titleImageId === image.sextantId;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-gallery {
    > .title {
      @include project-details-block-title;
    }

    > .gallery {
      display: grid;
      grid-template-areas: "images images" "title amount";
      grid-row-gap: 2space;

      > .images {
        display: grid;
        grid-area: images;
        grid-gap: 1space;
        grid-template-columns: repeat(3, 1fr);

        > .image {
          background-size: cover;
          position: relative;

          &::before {
            padding-top: 75%;
            content: "";
            display: block;
          }

          &.-title {
            box-shadow: 0 0 0 2px $sbh-palette-fir;
          }

          > .title {
            height: 25px;
            position: absolute;
            right: 1space;
            top: 1space;
            width: 25px;
          }

          > .trash {
            bottom: 1space;
            color: $sbh-palette-white;
            height: 25px;
            left: 1space;
            position: absolute;
            width: 25px;

            display: none;
          }

          > .selection {
            bottom: 1space;
            position: absolute;
            right: 1space;

            > .input {
              position: absolute;
              visibility: hidden;

              &:checked + .trigger {
                border-color: currentColor;
                color: $sbh-palette-fir;

                &::after {
                  content: "✔";
                }
              }
            }

            > .trigger {
              align-items: center;
              background-color: $sbh-palette-white;
              border: 2px solid $sbh-palette-timberwolf;
              display: flex;
              height: 25px;
              justify-content: center;
              line-height: 1;
              width: 25px;
            }
          }
        }
      }

      > .amount {
        grid-area: amount;
        text-align: right;
      }

      > .title {
        grid-area: title;
      }
    }
  }
</style>
