<template>
  <is-loading
    :request="request"
    :label="$t('label.isLoading')"
    :error-label="$t('label.loadError')"
    class="app-layout -project project-view"
  >
    <template #indicator="{ isWaiting, label }">
      <div
        v-if="isWaiting"
        class="content -fake"
      >
        <div class="blocks">
          <div class="block">
            <div class="is-loading">
              <span class="indicator" />
              <span
                v-if="label"
                class="label"
              >{{ label }}</span>
            </div>
          </div>
          <div class="block">
            <div class="fake-paragraph" />
          </div>
        </div>
        <div class="sidebar">
          <div class="block">
            <div class="fake-paragraph" />
          </div>
        </div>
      </div>
    </template>

    <template #wait>
      <project-details-header
        :project="result"
        :funding-status="localState.fundingStatus || result.status"
        :ready-internal-at="localState.readyInternalAt || result.readyInternalAt"
        :translator="translator"
        :language="language"
        :edit-mode="edit"
        :funding-states="masterDataRepository.fundingStates"
        :translators="masterDataRepository.translators"
        :copywriters="masterDataRepository.copywriters"
        :copywriter="copywriter"
        :use-favorite="access.favorites"
        :use-project-list="access.projectList"
        class="header"
        @change="onChange"
        @translate="onTranslate"
        @save="onSave"
        @cancel="onCancel"
        @back="onBack"
        @preview="previewVisible = true"
        @project-list-toggled="updateFinancePlanIfRemovedFromList($event)"
      />

      <section class="content">
        <overlay-container
          :visible="previewVisible"
          @close="previewVisible = false"
        >
          <project-list-export-preview
            :project="result"
            :locale="language"
          />
        </overlay-container>

        <div class="blocks">
          <project-details-notes
            :notes="localState.notes || result.notes"
            :edit-mode="edit"
            class="block"
            @change="onContentChange"
          />

          <project-details-copy
            v-if="language === 'de'"
            key="de-details"
            :lead="localizedLeads.de"
            :text="localizedTextPmModels.de"
            :title="localizedTitles.de"
            :acknowledgment="localizedAcknowledgments.de"
            :edit-mode="edit"
            class="block"
            @change="onContentChange"
          />

          <project-details-copy
            v-else
            key="fr-details"
            :lead="localizedLeads.fr"
            :text="localizedTextPmModels.fr"
            :title="localizedTitles.fr"
            :acknowledgment="localizedAcknowledgments.fr"
            :edit-mode="edit"
            class="block"
            @change="onContentChange"
          />

          <project-details-gallery
            :images="result.images"
            :edit-mode="edit"
            class="block"
            @change="onContentChange"
          />

          <project-details-map
            :coordinates="result.coordinates"
            :name="result.name"
            :street="result.street"
            :zip="result.zip"
            :city="result.city"
            :canton="result.canton"
            class="block"
          />

          <project-details-document-list
            :documents="result.documents"
            class="block"
          />
        </div>

        <aside class="sidebar">
          <project-details-finances
            :active-list="activeList"
            :active-donor="activeDonor"
            :finance-plan="financePlan"
            :name="result.name"
            :reservations="reservations"
            :short-name="result.shortName"
            :project="result"
            :class="{ block: true, '-locked': edit }"
            @reservation-updated="updateFinancePlan"
          />

          <project-details-contact-information
            :applicant="result.applicant"
            :expert="result.expert"
            :copywriter="result.copywriter"
            :translator="result.translator"
            class="block"
          />
        </aside>
      </section>

      <overlay-container
        :visible="translate"
        @close="translate = false"
      >
        <translation-panel
          :language="language"
          :leads="localizedLeads"
          :texts="localizedTextPmModels"
          :titles="localizedTitles"
          :acknowledgments="localizedAcknowledgments"
          @apply="onContentChange"
          @cancel="translate = false"
        />
      </overlay-container>
    </template>
  </is-loading>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';

  import ProjectListModule from '@/domain/list/ProjectListModule';
  import UserModule from '@/domain/user/UserModule';

  import { supported } from '@/domain/master-data/Language';

  import IsLoading from '@/components/IsLoading.vue';
  import OverlayContainer from '@/components/OverlayContainer.vue';
  import ProjectDetailsContactInformation from '@/components/project/details/ProjectDetailsContactInformation.vue';
  import ProjectDetailsCopy from '@/components/project/details/ProjectDetailsCopy.vue';
  import ProjectDetailsDocumentList from '@/components/project/details/ProjectDetailsDocumentList.vue';
  import ProjectDetailsFinances from '@/components/project/details/ProjectDetailsFinances.vue';
  import ProjectDetailsGallery from '@/components/project/details/ProjectDetailsGallery.vue';
  import ProjectDetailsHeader from '@/components/project/details/ProjectDetailsHeader.vue';
  import ProjectDetailsMap from '@/components/project/details/ProjectDetailsMap.vue';
  import ProjectDetailsNotes from '@/components/project/details/ProjectDetailsNotes.vue';
  import TranslationPanel from '@/components/translation/TranslationPanel.vue';
  import ProjectListExportPreview from '@/components/list/ProjectListExportPreview.vue';
  import Translator from '@/domain/master-data/Translator';
  import Copywriter from '@/domain/master-data/Copywriter';

  const { mapGetters: mapUserGetters } = createNamespacedHelpers(UserModule.Namespace);

  const {
    mapGetters: mapProjectListGetters,
  } = createNamespacedHelpers(ProjectListModule.Namespace);

  export default {
    name: 'Project',
    inject: [
      'repositories',
    ],
    components: {
      ProjectListExportPreview,
      IsLoading,
      OverlayContainer,
      ProjectDetailsContactInformation,
      ProjectDetailsCopy,
      ProjectDetailsDocumentList,
      ProjectDetailsFinances,
      ProjectDetailsGallery,
      ProjectDetailsHeader,
      ProjectDetailsMap,
      ProjectDetailsNotes,
      TranslationPanel,
    },
    props: {
      projectId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        previewVisible: false,
        request: null,
        language: supported[0],
        edit: false,
        translate: false,
        localState: {},
        result: null,
        translator: null,
        copywriter: null,
        financePlan: null,
        reservations: [],
      };
    },
    computed: {
      masterDataRepository() {
        return this.repositories.masterData;
      },
      projectRepository() {
        return this.repositories.project;
      },
      financeRepository() {
        return this.repositories.finance;
      },
      ...mapUserGetters([
        UserModule.Getters.access,
      ]),
      ...mapProjectListGetters([
        ProjectListModule.Getters.activeDonor,
        ProjectListModule.Getters.activeList,
      ]),
      localizedLeads() {
        return {
          ...this.result.localizedLeads,
          ...this.localState.localizedLeads,
        };
      },
      localizedTextPmModels() {
        return {
          ...this.result.localizedTexts,
          ...this.localState.localizedTextPmModels,
        };
      },
      localizedTitles() {
        return {
          ...this.result.localizedTitles,
          ...this.localState.localizedTitles,
        };
      },
      localizedAcknowledgments() {
        return {
          ...this.result.localizedAcknowledgments,
          ...this.localState.localizedAcknowledgments,
        };
      },
      reservationForDonor() {
        if (!this.activeDonor) {
          return null;
        }

        return (this.reservations || [])
          .find(({ donor }) => donor && donor.id === this.activeDonor.id) ?? null;
      },
    },
    async created() {
      this.request = this.projectRepository.readProject(this.projectId);
      this.result = await this.request;
      this.financePlan = this.result.financePlan;
      this.reservations = this.result.reservations;
      if (this.result.translator) {
        this.translator = new Translator({
          id: this.result.translator.sextantId,
          name: this.result.translator.name,
        });
      }
      if (this.result.copywriter) {
        this.copywriter = new Copywriter({
          id: this.result.copywriter.sextantId,
          name: this.result.copywriter.name,
        });
      }
    },
    methods: {
      jsonToText(json) {
        if (!json.content || !json.content[0] || !json.content[0].content) {
          return null;
        }

        return new DOMParser()
          .parseFromString(json.content[0].content[0].text, 'text/html')
          .body
          .textContent;
      },
      async update() {
        const localState = { ...this.localState };

        [
          localState.localizedTitles,
          localState.localizedLeads,
          localState.localizedAcknowledgments,
        ].forEach((item) => {
          if (item === undefined || item === null) {
            return;
          }

          ['de', 'fr'].forEach((language) => {
            if (item[language]) {
              // eslint-disable-next-line no-param-reassign
              item[language] = this.jsonToText(item[language]);
            }
          });
        });

        this.request = this.projectRepository.updateProject(this.result.id, localState);
        this.result = await this.request;
        this.financePlan = this.result.financePlan;
        this.reservations = this.result.reservations;
        if (this.result.translator) {
          this.translator = new Translator({
            id: this.result.translator.sextantId,
            name: this.result.translator.name,
          });
        }
        if (this.result.copywriter) {
          this.copywriter = new Copywriter({
            id: this.result.copywriter.sextantId,
            name: this.result.copywriter.name,
          });
        }
      },
      async updateFinancePlanIfRemovedFromList(activeInList) {
        // HACK: if project was removed, we know that a reservation (if available) would be removed, so set amount=0
        if (!activeInList && this.reservationForDonor) {
          this.reservationForDonor.amount = 0;
          return this.updateFinancePlan(this.reservationForDonor);
        }
        return null;
      },
      async updateFinancePlan(updatedReservation) {
        if (!this.result || !updatedReservation) {
          return;
        }

        const reservations = [...this.reservations];
        const index = reservations.findIndex((r) => r.sextantId === updatedReservation.sextantId);

        if (index >= 0) {
          reservations[index] = updatedReservation;
        } else {
          reservations.push(updatedReservation);
        }

        this.reservations = reservations;

        const { investmentVolume } = this.financePlan;
        this.financePlan = await this.financeRepository.readFinancePlanForProject(this.result.id);
        this.financePlan.investmentVolume = investmentVolume;
      },
      onChange({
        language, edit, fundingStatus, readyInternalAt, translator, copywriter,
      }) {
        if (language !== undefined) {
          this.language = language;
        }

        if (edit !== undefined) {
          this.edit = edit;
        }

        if (fundingStatus !== undefined) {
          this.$set(this.localState, 'statusId', fundingStatus.id);
          this.$set(this.localState, 'fundingStatus', fundingStatus);
        }
        if (translator !== undefined) {
          this.$set(this.localState, 'translatorId', translator.id);
          this.$set(this.localState, 'translator', translator);
        }
        if (copywriter !== undefined) {
          this.$set(this.localState, 'copywriterId', copywriter.id);
          this.$set(this.localState, 'copywriter', copywriter);
        }
        if (readyInternalAt !== undefined) {
          this.$set(this.localState, 'readyInternalAt', readyInternalAt);
        }
      },
      onTranslate() {
        this.translate = true;
      },
      onSave() {
        this.update();
        this.localState = {};
        this.edit = false;
      },
      onBack() {
        if (this.access.projectList === true) {
          return this.$router.replace({ name: 'projects' });
        }

        return this.$router.replace('/');
      },
      onCancel() {
        this.localState = {};
        this.edit = false;
      },
      onContentChange(data) {
        const { localState, language } = this;

        const {
          localizedTitles,
          localizedLeads,
          localizedTextPmModels,
          localizedAcknowledgments,
          notes,
          titleImageId,
          selectedImageIds,
          locale,
        } = data;

        this.translate = false;

        if (localizedTitles) {
          this.$set(localState, 'localizedTitles', {
            ...(localState.localizedTitles || {}),
            ...{ [locale || language]: localizedTitles },
          });
        }

        if (localizedLeads) {
          this.$set(localState, 'localizedLeads', {
            ...(localState.localizedLeads || {}),
            ...{ [locale || language]: localizedLeads },
          });
        }

        if (localizedTextPmModels) {
          this.$set(localState, 'localizedTextPmModels', {
            ...(localState.localizedTextPmModels || {}),
            ...{ [locale || language]: localizedTextPmModels },
          });
        }

        if (localizedAcknowledgments) {
          this.$set(localState, 'localizedAcknowledgments', {
            ...(localState.localizedAcknowledgments || {}),
            ...{ [locale || language]: localizedAcknowledgments },
          });
        }

        if (notes) {
          this.$set(localState, 'notes', notes);
        }

        if (titleImageId !== undefined) {
          this.$set(localState, 'titleImageId', titleImageId);
        }

        if (selectedImageIds) {
          this.$set(localState, 'selectedImageIds', selectedImageIds);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@gridonic/components/styles/button-plain";
  @import "~@/sass/meta";

  .project-view {
    > .header,
    > .content {
      margin-top: 4space;
      margin-bottom: 4space;
    }

    > .content {
      display: grid;
      grid-column-gap: 4space;
      grid-template-columns: 2fr 1fr;
      margin-top: 0;

      &.-fake {
        margin: 4space auto;
      }

      > .blocks,
      > .sidebar {
        display: grid;
        grid-auto-rows: max-content;
        grid-row-gap: 2space;

        > .block {
          background-color: $sbh-palette-white;
          padding: 3space;

          &.-locked {
            cursor: not-allowed;
            position: relative;
            user-select: none;

            &::after {
              background-blend-mode: soft-light;
              background-color: mix($sbh-palette-black, $sbh-palette-white, 14%);
              background-image: url("~@/assets/icons/lock-closed.svg");
              background-origin: content-box;
              background-position: center center;
              background-repeat: no-repeat;
              background-size: contain;
              content: "";
              height: 100%;
              left: 0;
              opacity: 0.5;
              padding: 2space;
              position: absolute;
              top: 0;
              width: 100%;
              z-index: $z-lock;
            }
          }
        }
      }
    }
  }
</style>
