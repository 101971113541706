// eslint-disable-next-line import/prefer-default-export
export const format = (value) => (value ? new Intl.DateTimeFormat('de-CH', {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
}).format(value) : null);

export const formatVerbose = (value, lang = 'de') => (value ? new Intl.DateTimeFormat(lang, {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
}).format(value) : null);

export const thirtyDaysLater = new Date(Date.now() + (60 * 60 * 24 * 30 * 1000));
