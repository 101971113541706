<template>
  <div class="tag-list">
    <slot class="tag" />
    <tag-item
      v-for="(tag, index) in filtered"
      :key="`tag-${index}`"
      :tag="tag"
      :icon="tag.icon || null"
      class="tag"
      :class="{ '-primary': tag && tag.primary === true }"
    />
  </div>
</template>

<script>
  import TagItem from '@/components/TagItem.vue';

  const isEmpty = (value) => value !== undefined && value !== null && value !== '';

  export default {
    name: 'TagList',
    components: {
      TagItem,
    },
    props: {
      tags: {
        type: Array,
        required: true,
      },
    },
    computed: {
      filtered() {
        return this
          .tags
          .filter((item) => isEmpty(Array.isArray(item) ? item[0] : item));
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .tag-list {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    user-select: none;

    > .tag {
      &:not(:only-child) {
        margin-bottom: 0.5space;
        margin-right: 0.5space;
      }
    }
  }
</style>
