import ApiConnector from '@/core/api/ApiConnector';

export default class AjaxApiConnector extends ApiConnector {
  constructor(client, serverRoutes) {
    super();
    this.client = client;
    this.serverRoutes = serverRoutes;
  }

  async create(name, data, params) {
    return this.postToUrl(this.serverRoutes.generate(name, params), data);
  }

  async read(name, params) {
    return this.getFromUrl(this.serverRoutes.generate(name, params));
  }

  async update(name, data, params) {
    return this.putToUrl(this.serverRoutes.generate(name, params), data);
  }

  async delete(name, params) {
    return this.deleteForUrl(this.serverRoutes.generate(name, params));
  }

  async postToUrl(url, data, params = {}) {
    const response = await this.client.post(url, data, params);

    return response.data;
  }

  async getFromUrl(url) {
    const response = await this.client.get(url, {});

    return response.data;
  }

  async putToUrl(url, data) {
    const response = await this.client.put(url, data, {});

    return response.data;
  }

  async deleteForUrl(url) {
    const response = await this.client.delete(url, {});

    return response.data;
  }
}
