import '@/sass/styles.scss';

import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

import { logger, log } from '@/lib-glue/logger';
import { AxiosAjaxClient, SentryErrorTracker } from '@gridonic/client-services';
import AppInfo from '@/AppInfo';

import ServerRoutes from '@/core/api/ServerRoutes';
import RootStore from '@/store/store';
import ProjectRepository from '@/domain/project/ProjectRepository';
import FinanceRepository from '@/domain/project/FinanceRepository';
import AjaxApiConnector from '@/core/api/AjaxApiConnector';
import MasterDataRepository from '@/domain/master-data/MasterDataRepository';
import FavoriteRepository from '@/domain/user/FavoriteRepository';
import ProjectListRepository from '@/domain/list/ProjectListRepository';
import UserRepository from '@/domain/user/UserRepository';
import ProjectConverter from '@/domain/project/ProjectConverter';
import DonorRepository from '@/domain/donor/DonorRepository';
import ReservationConverter from '@/domain/donor/ReservationConverter';
import ReservationRepository from '@/domain/donor/ReservationRepository';
import DonorConverter from '@/domain/donor/DonorConverter';
import NotificationModule from '@/domain/notification/NotificationModule';
import NotificationBus from '@/domain/notification/NotificationBus';
import WritingTaskRepository from '@/domain/task/WritingTaskRepository';
import ProjectListExport from '@/domain/list/ProjectListExport';
import OverlayModule from '@/domain/overlay/OverlayModule';
import NotificationAction from '@/domain/notification/NotificationAction';
import UserModule from '@/domain/user/UserModule';
import ProjectListModule from '@/domain/list/ProjectListModule';
import ProjectSearchFilterRepository from '../domain/project/ProjectSearchFilterRepository';
import ProjectSearchFilterConverter from '../domain/project/ProjectSearchFilterConverter';

export default class AppMain {
  constructor() {
    this.appInfo = new AppInfo();
  }

  init() {
    this.startErrorTracking()
      .createContainer();

    return this
      .createVueApp()
      .onSetupCompleted()
      .logAppStartupInfo();
  }

  startErrorTracking() {
    this.errorTracker = new SentryErrorTracker(
      logger, {
        id: this.appInfo.sentryDsn,
        environment: this.appInfo.appEnvironment,
        projectName: this.appInfo.projectName,
        version: this.appInfo.appVersion,
        vue: Vue,
      },
    );

    this.errorTracker.start();

    return this;
  }

  createContainer() {
    const serverRoutes = new ServerRoutes();

    const ajaxClient = new AxiosAjaxClient();
    const connector = new AjaxApiConnector(ajaxClient, serverRoutes);

    const $dataUser = document.querySelector('[data-user]');
    const userData = $dataUser ? JSON.parse(
      $dataUser.dataset.user,
    ) : {};

    const masterData = JSON.parse(
      document.querySelector('[data-master-data]').dataset.masterData,
    );

    const userRepository = new UserRepository(connector, userData);
    const masterDataRepository = new MasterDataRepository(masterData);

    let reservationConverter = null;
    const donorConverter = new DonorConverter((r) => reservationConverter.convertReservation(r));

    reservationConverter = new ReservationConverter(masterDataRepository, donorConverter);
    const projectConverter = new ProjectConverter(masterDataRepository, reservationConverter);
    const projectSerachFilterConverter = new ProjectSearchFilterConverter(masterDataRepository);

    this.container = {
      config: this.config,
      converters: {
        donor: donorConverter,
        reservation: reservationConverter,
        project: projectConverter,
      },
      repositories: {
        user: userRepository,
        masterData: masterDataRepository,
        project: new ProjectRepository(connector, projectConverter),
        projectSearchFilter:
          new ProjectSearchFilterRepository(connector, projectSerachFilterConverter),
        finance: new FinanceRepository(connector),
        favorite: new FavoriteRepository(connector, projectConverter),
        projectList: this.createProjectListRepository(connector, projectConverter, donorConverter),
        donor: new DonorRepository(connector, donorConverter),
        reservation: new ReservationRepository(connector, reservationConverter),
        task: new WritingTaskRepository(connector, projectConverter),
      },
      services: {
        ajaxClient,
        projectListExport: new ProjectListExport(serverRoutes),
      },
      server: {
        routes: serverRoutes,
      },
      notificationBus: new NotificationBus(),
      router: this.createRouter(),
    };

    this.container.store = this.createStore();
    this.container.notificationBus.store = this.container.store;

    this.container.router.beforeEach((from, to, next) => {
      setTimeout(async () => this.checkAutoLogout());
      this.container.notificationBus.close();
      next();
    });

    this.container.router.afterEach(() => {
      document.documentElement.classList.toggle('is-locked', false);
      this.container.store.dispatch(
        `${OverlayModule.Namespace}/${OverlayModule.Actions.onClose}`,
      );
    });

    window.addEventListener('focus', async () => this.checkAutoLogout(ajaxClient));
  }

  createVueApp() {
    Vue.config.productionTip = false;
    Vue.prototype.$locale = this.getLocale();

    Vue.mixin({
      methods: {
        $t(key, placeholders = {}, domain = 'messages') {
          return window.Translator.trans(key, placeholders, domain, this.$locale);
        },
        /**
         * @param {NotificationType} type
         * @param {string }message
         * @param {NotificationActions[]} actions
         *
         * @returns {Promise<any>}
         */
        $notify: async (
          // eslint-disable-next-line max-len
          type, message, actions = []) => this.container.notificationBus.notify(type, message, actions),
        $notifyYesNo:
          // eslint-disable-next-line max-len
          async (type, message, handler) => this.container.notificationBus.notify(type, message, [
            new NotificationAction({
              text: 'label.yes',
              handler: async () => handler(),
            }),
            new NotificationAction({
              text: 'label.no',
              handler: async () => {},
            }),
          ]),
      },
    });

    Vue.prototype.$eventBus = new Vue();

    new Vue({
      router: this.container.router,
      store: this.container.store,
      provide: {
        config: this.container.config,
        converters: this.container.converters,
        repositories: this.container.repositories,
        services: this.container.services,
      },
      render: (h) => h(this.vueApp, {
        props: this.rootProps,
      }),
    }).$mount('#app');

    return this;
  }

  createStore() {
    Vue.use(Vuex);

    const rootStore = new RootStore(this.storeModules);

    return new Vuex.Store(rootStore);
  }

  createRouter() {
    Vue.use(VueRouter);

    return this.router;
  }

  logAppStartupInfo() {
    log.app.info(
      'Initialized', {
        app: this.appName,
        environment: this.appInfo.appEnvironment,
        version: this.appInfo.appVersion,
        logLevel: this.appInfo.logLevel,
      },
    );

    return this;
  }

  get appName() {
    throw new Error('appName must be overriden in subclass');
  }

  get router() {
    throw new Error('router must be overriden in subclass');
  }

  get vueApp() {
    throw new Error('vueApp must be overriden in subclass');
  }

  get storeModules() {
    return {
      [UserModule.Namespace]: new UserModule(this.userAccess),
      [NotificationModule.Namespace]: new NotificationModule(),
      [OverlayModule.Namespace]: new OverlayModule(),
      [ProjectListModule.Namespace]: new ProjectListModule({
        projectListRepository: this.container.repositories.projectList,
        userRepository: this.container.repositories.user,
        notificationBus: this.container.notificationBus,
        router: this.container.router,
      }),
    };
  }

  get config() {
    return {
      defaultPagingSize: parseInt(process.env.VUE_APP_DEFAULT_PAGING_SIZE ?? 10, 0),
      donation: {
        amountSliderMin: [0, 1000], // passed to FormSlider component
        amountSliderRange: [1000, 1], // passed to FormSlider component
      },
    };
  }

  get handleAutoLogout() {
    return true;
  }

  get userAccess() {
    return {
      favorites: false,
      projectList: false,
    };
  }

  get rootProps() {
    return {};
  }

  getLocale() {
    const defaultLocale = 'de';
    const element = document.querySelector('[data-locale]');

    if (!element) {
      return defaultLocale;
    }

    return element.dataset.locale ?? defaultLocale;
  }

  createProjectListRepository(connector, projectConverter, donorConverter) {
    return new ProjectListRepository(connector, projectConverter, donorConverter);
  }

  onSetupCompleted() {
    return this;
  }

  async checkAutoLogout() {
    if (!this.handleAutoLogout) {
      return;
    }

    const response = await this.container.services.ajaxClient
      .get(this.container.server.routes.generate('app_logged_in'));

    if (response.data.loggedIn !== true) {
      window.location.href = '/';
    }
  }
}
