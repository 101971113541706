export default class NotificationModule {
  static get Namespace() {
    return 'notification';
  }

  get namespaced() {
    return true;
  }

  get state() {
    return {
      notification: null,
      timeout: null,
    };
  }

  get getters() {
    return {
      [NotificationModule.Getters.notification]: (state) => state.notification,
    };
  }

  get mutations() {
    return {
      [NotificationModule.Mutations.setNotification]:
        (state, { notification, timeout }) => {
          state.notification = notification;
          state.timeout = timeout;
        },
    };
  }

  get actions() {
    return {
      [NotificationModule.Actions.notify]: ({ commit, dispatch, state }, notification) => {
        let timeout = null;

        if (state.timeout !== null) {
          clearTimeout(state.timeout);
        }

        if (notification.actions.length === 0) {
          timeout = setTimeout(
            () => dispatch(NotificationModule.Actions.close),
            3000,
          );
        }

        commit(NotificationModule.Mutations.setNotification, { notification, timeout });
      },
      [NotificationModule.Actions.runAction]: ({ dispatch }, action) => {
        action.handler();
        dispatch(NotificationModule.Actions.close);
      },
      [NotificationModule.Actions.close]: ({ commit }) => {
        commit(NotificationModule.Mutations.setNotification, { notification: null, timeout: null });
      },
    };
  }

  static get Getters() {
    return {
      notification: 'notification',
    };
  }

  static get Mutations() {
    return {
      setNotification: 'setNotification',
    };
  }

  static get Actions() {
    return {
      notify: 'notify',
      runAction: 'runAction',
      close: 'close',
    };
  }
}
