import {}
  from 'vuex';

export { Store } from 'vuex';

export default class RootStore {
  constructor(storeModules) {
    this.storeModules = storeModules;
  }

  get modules() {
    return this.storeModules;
  }
}
