import ApiRepository from '@/core/api/ApiRepository';
import ReservationSearchResult from '@/domain/donor/ReservationSearchResult';

export default class ReservationRepository extends ApiRepository {
  /**
   * @param {ApiConnector} connector
   * @param {ReservationConverter} converter
   */
  constructor(connector, converter) {
    super(connector);

    this.converter = converter;
  }

  /**
   * @param {ReservationSearchFilter} reservationSearchFilter
   *
   * @returns {Promise<ReservationSearchResult>}
   */
  async readReservationsBySearchFilter(reservationSearchFilter) {
    const result = await this.connector.create(
      'api_reservation_search',
      reservationSearchFilter.data,
    );

    return new ReservationSearchResult(
      result.page,
      result.totalHits,
      result.totalPages,
      (result.reservations || []).map((r) => this.convertReservation(r)),
    );
  }

  /**
   * @param {Reservation} reservation
   *
   * @returns {Promise<Reservation>}
   */
  async createReservation(reservation) {
    const result = await this.connector.create(
      'api_reservation_create',
      this.requestDataFromReservation(reservation),
    );

    return this.convertReservation(result.reservation);
  }

  /**
   * @param {Reservation} reservation
   *
   * @returns {Promise<Reservation>}
   */
  async updateReservation(reservation) {
    const result = await this.connector.update(
      'api_reservation_update',
      this.requestDataFromReservation(reservation), {
        reservationId: reservation.sextantId,
      },
    );

    return this.convertReservation(result.reservation);
  }

  /**
   * @private
   */
  convertReservation(responseData) {
    return this.converter.convertReservation(responseData);
  }

  /**
   * @param {Reservation} reservation
   *
   * @private
   */
  requestDataFromReservation(reservation) {
    return this.converter.requestDataFromReservation(reservation);
  }
}
