import NotificationModule from '@/domain/notification/NotificationModule';

export default class OverlayModule {
  static get Namespace() {
    return 'overlay';
  }

  get namespaced() {
    return true;
  }

  get state() {
    return {
      visible: null,
    };
  }

  get getters() {
    return {
      [OverlayModule.Getters.visible]: (state) => state.visible,
    };
  }

  get mutations() {
    return {
      [OverlayModule.Mutations.setVisible]:
        (state, visible) => { state.visible = visible; },
    };
  }

  get actions() {
    return {
      [OverlayModule.Actions.onOpen]: ({ commit, dispatch }) => {
        dispatch(`${NotificationModule.Namespace}/${NotificationModule.Actions.close}`, null, { root: true });

        commit(OverlayModule.Mutations.setVisible, true);
      },
      [OverlayModule.Actions.onClose]: ({ commit, dispatch }) => {
        dispatch(`${NotificationModule.Namespace}/${NotificationModule.Actions.close}`, null, { root: true });

        commit(OverlayModule.Mutations.setVisible, false);
      },
    };
  }

  static get Getters() {
    return {
      visible: 'visible',
    };
  }

  static get Mutations() {
    return {
      setVisible: 'setVisible',
    };
  }

  static get Actions() {
    return {
      onOpen: 'onOpen',
      onClose: 'onClose',
    };
  }
}
