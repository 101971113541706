export default class UserModule {
  constructor(access) {
    this.access = access;
  }

  static get Namespace() {
    return 'user';
  }

  get namespaced() {
    return true;
  }

  get state() {
    return {
      access: this.access ?? {
        favorites: false,
      },
    };
  }

  get getters() {
    return {
      [UserModule.Getters.access]: (state) => state.access,
    };
  }

  get mutations() {
    return {
    };
  }

  get actions() {
    return {
    };
  }

  static get Getters() {
    return {
      access: 'access',
    };
  }

  static get Mutations() {
    return {
    };
  }

  static get Actions() {
    return {
    };
  }
}
