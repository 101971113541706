<template>
  <div
    class="notification-panel"
    :class="[ `-${type}` ]"
  >
    <div class="container">
      <span><strong>{{ $t(`notification.common.${type}`) }}</strong> </span>

      <span>{{ $t(message) }}</span>

      <a
        v-for="(action, index) in actions"
        :key="`notification-action-${index}`"
        @click="$emit('run-action', action)"
      >
        {{ $t(action.text) }}
      </a>

      <a
        v-if="closeable"
        type="button"
        class="close"
        @click="$emit('close')"
      >
        <icon-set icon="cross" />
      </a>
    </div>
  </div>
</template>

<script>
  import IconSet from '@/components/IconSet.vue';

  export default {
    name: 'NotificationPanel',
    components: { IconSet },
    props: {
      type: {
        type: String,
        required: true,
      },
      message: {
        type: String,
        default: '',
      },
      actions: {
        type: Array,
        default: () => [],
      },
      closeable: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";
  @import "~@/sass/vendor";

  .notification-panel {
    position: relative;
    padding-top: 0.25space;
    padding-bottom: 0.25space;
    background-color: #cfcfcf;
    border: 2px solid #bfbfbf;
    color: #373736;

    > .container {
      position: relative;
      max-width: $grid-max-width;
      margin: 0 auto;
      padding-left: $grid-outer-margin;
      padding-right: $grid-outer-margin;

      > span {
        margin-right: 1space;
      }

      > a {
        color: inherit;
        text-decoration: underline;
        margin-right: 1space;
        cursor: pointer;
      }

      > a.close {
        position: absolute;
        top: 50%;
        right: $grid-outer-margin;
        transform: translateY(-10px);
        margin-left: 1space;

        > .icon {
          min-width: 12px;
          min-height: 12px;
          width: 12px;
        }
      }
    }

    &.-success {
      color: #123734;
      background-color: #9ac1bd;
      border-color: #0a635b;
    }

    &.-warning {
      color: #55503e;
      background-color: #ded099;
      border-color: #f0c32c;
    }

    &.-danger {
      color: #5e1126;
      background-color: #ce9ba9;
      border-color: #bd1441;
    }
  }
</style>
