<template>
  <span class="tag-item">
    <icon-set
      v-if="topicIcon"
      :icon="topicIcon"
      class="topic"
    />

    <span class="label">
      {{ print(tag) }}
    </span>

    <icon-set
      v-if="isBool"
      :icon="tag[0] ? 'yes' : 'no'"
      :class="`choice -${tag[0] ? 'yes' : 'no'}`"
    />
  </span>
</template>

<script>
  import { format } from '@/domain/date/Date';
  import IconSet from '@/components/IconSet.vue';

  const topicMap = {
    1037: 'education',
    1036: 'energy',
    1040: 'health',
    1039: 'industry',
    1031: 'farming',
    1032: 'farming',
    1035: 'tourism',
    1034: 'forest-and-wood',
  };

  export default {
    name: 'TagItem',
    components: {
      IconSet,
    },
    props: {
      icon: {
        type: [String, Number],
        default: null,
      },
      tag: {
        type: [String, Object, Array, Date],
        required: true,
      },
    },
    computed: {
      topicIcon() {
        return (this.icon && topicMap[this.icon]);
      },
      isBool() {
        return Array.isArray(this.tag) && this.tag.length === 2;
      },
    },
    methods: {
      print(tag) {
        if (tag.name !== undefined) {
          return tag.name;
        }

        if (tag instanceof Date) {
          return format(tag);
        }

        if (this.isBool) {
          if (Array.isArray(this.tag[1])) {
            return this.tag[0] ? this.tag[1][0] : this.tag[1][1];
          }

          return this.tag[1];
        }

        return tag;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .tag-item {
    align-items: center;
    background-color: $palette-fir-10;
    color: $sbh-palette-fir;
    display: flex;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 1.29;
    text-transform: uppercase;

    &.-mono {
      background-color: $sbh-palette-white;
      color: $sbh-palette-black;
    }

    &.-primary {
      background-color: #dfe6f6;
      color: #2251c1;
      font-weight: bold;
    }

    &.-alert {
      background-color: #ce9ba9;
      color: black;
    }

    > .topic {
      margin: 0 0 0 0.3125space;
    }

    > .label {
      padding: 0.25space 0.375space;
      hyphens: auto;
    }

    > .choice {
      background-color: rgba($sbh-palette-timberwolf, 0.25);
      height: 100%;

      &.-no { color: $sbh-palette-pheasant; }
    }
  }
</style>
