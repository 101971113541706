import Routing from 'fos-routing';
import routes from '../../../../public/js/fos_js_routes.json';

export default class ServerRoutes {
  constructor() {
    this.router = Routing;
    this.router.setData(routes);
  }

  generate(name, params = {}) {
    return this.router.generate(name, params);
  }
}
