import ApiRepository from '@/core/api/ApiRepository';

import Task from '@/domain/task/Task';
import TaskSearchResult from '@/domain/task/TaskSearchResult';

export default class WritingTaskRepository extends ApiRepository {
  constructor(connector, projectConverter) {
    super(connector);

    this.projectConverter = projectConverter;
  }

  /**
   * @param {TaskSearchFilter} searchFilter
   * @returns {Promise<TaskSearchResult>}
   */
  async readTasksBySearchFilter(searchFilter) {
    const result = await this.connector.create(
      'api_task_search',
      searchFilter.data,
    );

    return new TaskSearchResult(
      result.page,
      result.totalHits,
      result.totalPages,
      (result.tasks || []).map((t) => this.convertTask(t)),
    );
  }

  convertTask(data) {
    return new Task({
      ...data,
      deadline: data.deadline
        ? new Date(data.deadline * 1000) : null,
      project: data.project
        ? this.projectConverter.convertProject(data.project) : null,
    });
  }
}
