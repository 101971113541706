export default class ProjectImage {
  constructor(data) {
    this._sextantId = data.sextantId;
    this._filename = data.filename;
    this._url = data.url;
    this._localizedCaptions = data.localizedCaptions;
    this._isTitleImage = data.isTitleImage;
    this._isSelected = data.isSelected;
  }

  /**
   * @returns {int}
   */
  get sextantId() {
    return this._sextantId;
  }

  /**
   * @returns {string}
   */
  get filename() {
    return this._filename;
  }

  /**
   * @returns {string}
   */
  get url() {
    return this._url;
  }

  /**
   * @returns {{string}}
   */
  get localizedCaptions() {
    return this._localizedCaptions || {};
  }

  /**
   * @returns {boolean}
   */
  get isTitleImage() {
    return this._isTitleImage;
  }

  /**
   * @returns {boolean}
   */
  get isSelected() {
    return this._isSelected;
  }
}
