export default class ProjectDocument {
  constructor(data) {
    this._sextantId = data.sextantId;
    this._displayName = data.displayName;
    this._filename = data.filename;
    this._fileType = data.fileType;
    this._fileSize = data.fileSize;
    this._changedAt = data.changedAt;
    this._url = data.url;
  }

  /**
   * @returns {int}
   */
  get sextantId() {
    return this._sextantId;
  }

  /**
   * @returns {string}
   */
  get displayName() {
    return this._displayName;
  }

  /**
   * @returns {string}
   */
  get filename() {
    return this._filename;
  }

  /**
   * @returns {string}
   */
  get fileType() {
    return this._fileType;
  }

  /**
   * @returns {int}
   */
  get fileSize() {
    return this._fileSize;
  }

  /**
   * @returns {Date}
   */
  get changedAt() {
    return this._changedAt;
  }

  /**
   * @returns {string}
   */
  get url() {
    return this._url;
  }
}
