import SearchFilter from '@/core/search/SearchFilter';

export const SortProperty = Object.freeze({
  DONOR_NAME: 'donorName',
  DONOR_SEXTANT_ID: 'donorSextantId',
  MANAGER_NAME: 'managerName',
  PROJECT_COUNT: 'projectCount',
  UPDATED_AT: 'updatedAt',
  // STATUS: 'status',
  // UNTIL_DATE: 'untilDate',
  // DONOR: 'donor',
  // MANAGER: 'manager',
});

export default class ProjectListSearchFilter extends SearchFilter {
  get data() {
    return {
      page: this.page,
      hitsPerPage: this.hitsPerPage,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
  }
}
