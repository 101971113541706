import ApiRepository from '@/core/api/ApiRepository';
import ProjectList from '@/domain/list/ProjectList';
import Partner from '@/domain/user/Partner';
import ProjectListSearchResult from '@/domain/list/ProjectListSearchResult';

export default class ProjectListRepository extends ApiRepository {
  /**
   * @param {ApiConnector} connector
   * @param {ProjectConverter} projectConverter
   * @param {DonorConverter} donorConverter
   */
  constructor(connector, projectConverter, donorConverter) {
    super(connector);

    this.projectConverter = projectConverter;
    this.donorConverter = donorConverter;
  }

  /**
   * @param {ProjectListSearchFilter} searchFilter
   *
   * @returns {Promise<ProjectListSearchResult>}
   */
  async readProjectListsOfAuthenticatedUser(searchFilter) {
    const result = await this.connector.create(
      'api_project_list_user_all',
      searchFilter.data,
    );

    return new ProjectListSearchResult(
      result.page,
      result.totalHits,
      result.totalPages,
      (result.project_lists || []).map((f) => this.convertProjectList(f)),
    );
  }

  /**
   * @param {int} donorId
   *
   * @returns {Promise<ProjectListSearchResult>}
   */
  async readProjectListsOfDonor(donorId) {
    const result = await this.connector.read(
      'api_project_list_donor_all', {
        donorId,
      },
    );

    return (result.project_lists || []).map((f) => this.convertProjectList(f));
  }

  /**
   * @param {int} donorId
   * @param {int} projectId
   *
   * @returns {Promise<ProjectListSearchResult>}
   */
  async readProjectListsOfDonorAndProject(donorId, projectId) {
    const result = await this.connector.read(
      'api_project_list_donor_and_project_all', {
        donorId,
        projectId,
      },
    );

    return (result.project_lists || []).map((f) => this.convertProjectList(f));
  }

  /**
   * @returns {Promise<ProjectList>}
   */
  async createProjectList(donor) {
    const now = new Date();

    const projectList = new ProjectList({
      endOfLifeDate: new Date(now.setMonth(now.getMonth() + 1)),
      donor,
    });

    const result = await this.connector.create(
      'api_project_list_create',
      this.convertToRequestModel(projectList),
    );

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} projectListId
   *
   * @returns {Promise<ProjectList>}
   */
  async duplicateProjectList(projectListId) {
    const result = await this.connector.create(
      'api_project_list_duplicate', {
      }, {
        listId: projectListId,
      },
    );

    return this.convertProjectList(result.project_list);
  }

  /**
   * @returns {Promise<ProjectList>}
   */
  async deactivateProjectList() {
    await this.connector.update(
      'api_project_list_deactivate_project_list', {},
    );
  }

  /**
   * @param {ProjectList} projectList
   *
   * @returns {Promise<ProjectList>}
   */
  async updateProjectList(projectList) {
    const result = await this.connector.update(
      'api_project_list_update',
      this.convertToRequestModel(projectList), {
        listId: projectList.id,
      },
    );

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} projectListId
   * @param {int[]} projectIds
   *
   * @returns {Promise<ProjectList>}
   */
  async updateProjectListOrder(projectListId, projectIds) {
    const result = await this.connector.update(
      'api_project_list_update',
      { orderedProjectIds: projectIds }, {
        listId: projectListId,
      },
    );

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} projectListId
   *
   * @returns {Promise<ProjectList>}
   */
  async readProjectList(projectListId) {
    const result = await this.connector.read(
      'api_project_list_read', {
        listId: projectListId,
      },
    );

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} projectListId
   *
   * @returns {Promise<ProjectList>}
   */
  async persistProjectList(projectListId) {
    const result = await this.connector.update(
      'api_project_list_persist', {
      }, {
        listId: projectListId,
      },
    );

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} projectListId
   *
   * @returns {Promise<void>}
   */
  async deleteProjectList(projectListId) {
    return this.connector.delete(
      'api_project_list_delete', {
        listId: projectListId,
      },
    );
  }

  /**
   * @param {int} listId
   * @param {int} projectId
   *
   * @returns {Promise<ProjectList>}
   */
  async addProjectToList(listId, projectId) {
    const result = await this.connector.update('api_project_list_add_project', {}, {
      listId,
      projectId,
    });

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} listId
   * @param {{ projectIds: int[], reservationOnly: boolean }[]} projectIds
   *
   * @returns {Promise<ProjectList>}
   */
  async removeProjectsFromList(listId, { projectIds, reservationOnly }) {
    const result = await this.connector.update('api_project_list_remove_projects', { projectIds, reservationOnly }, {
      listId,
    });

    return this.convertProjectList(result.project_list);
  }

  /**
   * @param {int} listId
   *
   * @returns {Promise<ProjectList>}
   */
  async setActiveProjectListForCurrentUser(listId) {
    const result = await this.connector.update('api_project_list_user_activate', {}, {
      listId,
    });

    return this.convertProjectList(result.project_list);
  }

  async getActiveProjectListForCurrentUser() {
    const result = await this.connector.read('api_project_list_get_user');

    return this.convertProjectList(result.project_list);
  }

  convertProjectList(data) {
    if (!data) {
      return null;
    }

    return new ProjectList({
      ...data,
      status: data.status
        ? this.projectConverter.masterData.getProjectListStatus(data.status) : null,
      donor: data.donor ? this.donorConverter.convertDonor(data.donor) : null,
      manager: data.manager ? new Partner(data.manager) : null,
      updatedAt: data.updatedAt ? new Date(data.updatedAt * 1000) : null,
      endOfLifeDate: data.endOfLifeTimestamp ? new Date(data.endOfLifeTimestamp * 1000) : null,
      projects: (data.projects ?? []).map((p) => this.projectConverter.convertProject(p)),
    });
  }

  convertToRequestModel(projectList) {
    return {
      id: projectList.id,
      status: projectList.status ? projectList.status.id : null,
      donorId: projectList.donor ? projectList.donor.id : null,
      endOfLifeTimestamp: projectList.endOfLifeDate
        ? projectList.endOfLifeDate.getTime() / 1000 : null,
    };
  }
}
