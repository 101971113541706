import { PROJECT_LIST_STATUS } from '@/domain/list/ProjectListStatus';

export default class ProjectList {
  constructor(data) {
    this._id = data.id;
    this._status = data.status;
    this._updatedAt = data.updatedAt;
    this._manager = data.manager;
    this._donor = data.donor;
    this._isPersisted = data.isPersisted;
    this._endOfLifeDate = data.endOfLifeDate;
    this._projects = data.projects;
  }

  /**
   * @returns {int}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {ProjectListStatus}
   */
  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
  }

  /**
   * @returns {Date}
   */
  get updatedAt() {
    return this._updatedAt;
  }

  /**
   * @returns {int}
   */
  get manager() {
    return this._manager;
  }

  /**
   * @returns {Donor}
   */
  get donor() {
    return this._donor;
  }

  set donor(value) {
    this._donor = value;
  }

  /**
   * @returns {boolean}
   */
  get isPersisted() {
    return this._isPersisted;
  }

  /**
   * @returns {Date}
   */
  get endOfLifeDate() {
    return this._endOfLifeDate;
  }

  set endOfLifeDate(value) {
    this._endOfLifeDate = value;
  }

  /**
   * @returns {Project[]}
   */
  get projects() {
    return this._projects ?? [];
  }

  /**
   * @returns {boolean}
   */
  get readonly() {
    return !this.status || this.status.id === PROJECT_LIST_STATUS.SENT;
  }

  /**
   * @returns {number}
   */
  get totalReservedAmount() {
    return this.projects
      .flatMap((p) => p.reservations)
      .filter((r) => r.donor && this.donor && r.donor.id === this.donor.id)
      .reduce((sum, r) => sum + r.amount, 0);
  }

  /**
   * @param {int} projectId
   *
   * @returns {boolean}
   */
  containsProject(projectId) {
    return !!this.projects.find((p) => p.id === projectId);
  }
}
