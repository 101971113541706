<template>
  <header class="app-header site-header">
    <div class="container">
      <div class="branding">
        <router-link
          :to="{ name: 'home' }"
          class="logo"
        >
          <img :src="require('@/assets/images/logos/berghilfe/logo-reduced.svg').default">
          <span class="screenreader-only">{{ $t('navigation.home') }}</span>
        </router-link>
      </div>

      <div class="navigation">
        <ul class="site-navigation">
          <li
            v-for="({ name, classes, label }, index) in navigation"
            :key="`navigation-item-${index}`"
            class="item"
          >
            <slot :name="`navigation-${name}`">
              <router-link
                :key="`navigation-${index}`"
                :to="{ name }"
                :class="`link ${classes || ''}`"
              >
                {{ $t(`navigation.${label}`) }}
              </router-link>
            </slot>
          </li>
        </ul>
      </div>

      <div class="actions">
        <div
          ref="menu"
          :class="{ 'dropdown-menu': true, '-open': open }"
        >
          <div class="wrapper">
            <div
              class="trigger -with-icon"
              @click="open = !open"
            >
              <icon-set icon="person" /> <span class="_hidden-lg-down">{{ user.name }}</span>
              <div class="caption">
                ID {{ user.externalId }}
              </div>
            </div>
            <ul class="menu">
              <li
                v-for="({ name, icon, classes, label }, index) in menu"
                :key="`menu-item-${index}`"
                class="item"
              >
                <router-link
                  :key="`menu-${index}`"
                  :to="{ name }"
                  :class="`link ${classes || ''}`"
                >
                  <icon-set :icon="icon" /> {{ $t(`navigation.${label}`) }}
                </router-link>
              </li>
              <li class="item">
                <a
                  href="/logout"
                  class="link"
                >
                  <icon-set icon="logout" /> {{ $t('navigation.logout') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isImpersonation"
      class="impersonation"
    >
      <notification-panel
        type="info"
        :message="`${$t('navigation.impersonation.impersonating')} ${user.name}`"
        :closeable="false"
        :actions="[exitImpersonationAction]"
        @run-action="$event.handler()"
      />
    </div>

    <notification
      v-if="!$store.state.overlay.visible"
      class="notification"
    />
  </header>
</template>

<script>
  import IconSet from '@/components/IconSet.vue';
  import Notification from '@/views/Notification.vue';
  import NotificationPanel from '@/components/NotificationPanel.vue';
  import NotificationAction from '@/domain/notification/NotificationAction';

  export default {
    name: 'AppHeader',
    components: { NotificationPanel, Notification, IconSet },
    props: {
      navigation: {
        type: Array,
        required: true,
        default: () => ([]),
      },
      menu: {
        type: Array,
        default: () => ([]),
      },
      user: {
        type: Object,
        required: true,
        default: () => ({ name: 'Unknown', id: '-' }),
      },
      isImpersonation: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        clickFn: null,
        open: false,
        exitImpersonationAction: new NotificationAction({
          text: 'navigation.impersonation.exit',
          handler() {
            window.location.replace('/?_switch_user=_exit');
          },
        }),
      };
    },
    created() {
      document.addEventListener('click', this.clickFn = this.onClick.bind(this));
    },
    beforeDestroy() {
      document.removeEventListener('click', this.clickFn);
    },
    methods: {
      onClick(e) {
        if (this.$refs.menu.contains(e.target) === false) {
          this.open = false;
        }
      },
    },
  };
</script>
