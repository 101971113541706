<template>
  <!-- eslint-disable vue/no-v-html  -->
  <i
    v-if="icon"
    class="icon-set icon"
    v-html="iconContent"
  />
</template>

<script>
  export default {
    name: 'IconSet',
    props: {
      icon: {
        type: String,
        required: true,
      },
    },
    computed: {
      iconContent() {
        if (this.icon) {
          // eslint-disable-next-line import/no-dynamic-require, global-require
          return require(`!!raw-loader!@/assets/icons/${this.icon}.svg`).default;
        }

        return null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon-set {
    display: inline-block;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    width: 18px;

    ::v-deep svg {
      height: 100%;
      width: 100%;
    }
  }
</style>
