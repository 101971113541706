import NotificationMessage from '@/domain/notification/NotificationMessage';
import NotificationModule from '@/domain/notification/NotificationModule';

export default class NotificationBus {
  setStore(store) {
    this.store = store;
  }

  async notify(type, message, actions = []) {
    const notification = new NotificationMessage({
      type,
      message,
      actions,
    });

    return this.store
      .dispatch(
        `${NotificationModule.Namespace}/${NotificationModule.Actions.notify}`,
        notification,
      );
  }

  close() {
    return this.store
      .dispatch(
        `${NotificationModule.Namespace}/${NotificationModule.Actions.close}`,
      );
  }
}
