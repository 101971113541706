export const SortDirection = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

export default class SearchFilter {
  constructor() {
    this._page = 0;
    this._hitsPerPage = 10;
    this._sortBy = null;
    this._sortDirection = null;
  }

  /**
   * @returns {number}
   */
  get page() {
    return this._page;
  }

  set page(value) {
    this._page = value;
  }

  /**
   * @returns {number}
   */
  get hitsPerPage() {
    return this._hitsPerPage;
  }

  set hitsPerPage(value) {
    this._hitsPerPage = value;
  }

  /**
   * @returns {SortProperty}
   */
  get sortBy() {
    return this._sortBy;
  }

  set sortBy(value) {
    this._sortBy = value;
  }

  /**
   * @returns {SortDirection}
   */
  get sortDirection() {
    return this._sortDirection;
  }

  set sortDirection(value) {
    this._sortDirection = value;
  }
}
