export default class NotificationMessage {
  constructor({ type, message, actions }) {
    this._type = type;
    this._message = message;
    this._actions = actions;
  }

  /**
   * @returns {NotificationType}
   */
  get type() {
    return this._type;
  }

  set type(value) {
    this._type = value;
  }

  /**
   * @returns {string}
   */
  get message() {
    return this._message;
  }

  set message(value) {
    this._message = value;
  }

  /**
   * @returns {NotificationAction}
   */
  get actions() {
    return this._actions;
  }

  set actions(value) {
    this._actions = value;
  }
}
