export default class ProjectListExport {
  /**
   * @param {ServerRoutes} serverRoutes
   */
  constructor(serverRoutes) {
    this.serverRoutes = serverRoutes;
  }

  pdfExportUrl(listId, locale, useReservations) {
    return this.serverRoutes.generate('app_export_project_list', {
      listId,
      useReservations: useReservations ? '1' : '0',
      _locale: locale,
    });
  }
}
