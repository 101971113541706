export const currency = 'CHF';
export const locale = 'de-CH';

export const format = (value, options = {}) => {
  const defaults = {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  // @see https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
  return new Intl.NumberFormat(locale, { ...defaults, ...options }).format(value);
};
