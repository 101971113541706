import SearchFilter from '@/core/search/SearchFilter';

export const SortProperty = Object.freeze({
  ID: 'sextantId',
  NAME: 'lastname',
  PLACE: 'city',
  LAST_USAGE_IN_LIST: 'lastUsageInList',
  MANAGER: 'manager',
  // Note: reservedAmount is not supported for now, due to technical reasons
  // RESERVED_AMOUNT: 'reservedAmount',
});

export default class DonorSearchFilter extends SearchFilter {
  constructor() {
    super();

    this._searchQuery = null;
    this._ownerId = null;
  }

  /**
   * @returns {string}
   */
  get searchQuery() {
    return this._searchQuery;
  }

  set searchQuery(value) {
    this._searchQuery = value;
  }

  /**
   * @returns {int}
   */
  get ownerId() {
    return this._ownerId;
  }

  set ownerId(value) {
    this._ownerId = value;
  }

  /**
   * @return {object}
   */
  get data() {
    return {
      page: this.page,
      hitsPerPage: this.hitsPerPage,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      searchQuery: this.searchQuery,
      ownerId: this.ownerId,
    };
  }
}
