import SearchFilter from '@/core/search/SearchFilter';

export const SortProperty = Object.freeze({
  NAME: 'projectName',
  SHORT_NAME: 'projectShortName',
  AMOUNT: 'amount',
  STATUS: 'status',
  UNTIL_DATE: 'untilDate',
  DONOR: 'donor',
  MANAGER: 'manager',
});

export default class ReservationSearchFilter extends SearchFilter {
  get data() {
    return {
      page: this.page,
      hitsPerPage: this.hitsPerPage,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
    };
  }
}
