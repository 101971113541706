<template>
  <div
    class="donor-info"
  >
    <div class="title">
      {{ $t('donor.info.title') }}
    </div>

    <div class="personal icon-text">
      <icon-set icon="person-fill-white" />
      <div class="name">
        {{ donor.nameReversed }}
      </div>
      <div class="id">
        ID {{ donor.externalId }}
      </div>
    </div>

    <address class="address icon-text">
      <icon-set icon="location-fill-white" />
      <div class="street">
        {{ donor.street }} {{ donor.streetNumber }}
      </div>
      <div class="place">
        {{ donor.zip }} {{ donor.city }}
      </div>
    </address>

    <div
      v-if="donor.email"
      class="email icon-text"
    >
      <icon-set icon="email-fill-white" />
      <a
        :href="`mailto:${donor.email}`"
        class="link"
      >{{ donor.email }}</a>
    </div>

    <div
      v-if="Array.isArray(donor.allPhones) && donor.allPhones.length > 0"
      class="phone icon-text"
    >
      <icon-set icon="phone-fill-white" />
      <table>
        <tr
          v-for="({ label, number }, index) in donor.allPhones"
          :key="`phone-${index}`"
        >
          <td>
            <b>{{ label }}</b>
          </td>
          <td>
            {{ number }}
          </td>
        </tr>
      </table>
    </div>

    <div
      v-if="donor.manager"
      class="manager"
    >
      <div class="panel manager-card">
        <div class="title">
          {{ $t('donor.info.manager') }}
        </div>
        <div class="name">
          <icon-set icon="person-fill-white" /> {{ donor.manager.name }}
        </div>
        <div class="email">
          <icon-set icon="email-fill-white" /> <a
            :href="`mailto:${donor.manager.email}`"
            class="link"
          >{{ donor.manager.email }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import IconSet from '@/components/IconSet.vue';
  import Donor from '@/domain/donor/Donor';

  export default {
    name: 'DonorInfo',
    components: { IconSet },
    props: {
      donor: {
        type: Donor,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .donor-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: 1space;
    background-color: $palette-fir-10;
    padding: 1.5space 1.75space;

    > .title {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      font-weight: bold;
    }

    > .personal {
      grid-row: 2 / 3;
      grid-column: 1 / 2;

      > .name {
        font-weight: bold;
      }
    }

    > .address {
      grid-row: 3 / -1;
      grid-column: 1 / 2;
      font-style: normal;
    }

    > .email {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }

    > .phone {
      grid-row: 3 / -1;
      grid-column: 2 / 3;
    }

    > .manager {
      grid-row: 1 / -1;
      grid-column: 3 / -1;
      padding-left: 4space;
      padding-top: 1space;
    }

    table td:first-child {
      padding-right: 1.75space;
    }
  }

  .manager-card {
    padding: 1.5space;
    background: $sbh-palette-white;

    > .title {
      font-weight: bold;
      margin-bottom: 1space;
    }

    > .name,
    > .email {
      > .icon {
        margin-right: 0.75space;
        color: $palette-fir;
      }
    }
  }
</style>
