<template>
  <header class="project-details-header">
    <a
      v-if="useProjectList"
      class="button-backlink"
      @click="$emit('back')"
    >
      <icon-set icon="arrow-left" />
      {{ $t('details.header.backToSearch') }}
    </a>

    <div class="title">
      <h1 class="text">
        {{ project.name }}
      </h1>

      <div class="actions">
        <toggle-favorite
          v-if="useFavorite"
          class="favorite"
          :project="project"
        />
        <toggle-project-list
          v-if="useProjectList"
          class="projectlist"
          :project="project"
          @toggled="$emit('project-list-toggled', $event)"
        />
      </div>
    </div>

    <div class="actions">
      {{ $t('details.header.editMode') }}
      <form-toggle
        v-model="edit"
        @change="triggerChange({ edit })"
      />

      <button
        v-if="!editMode"
        type="button"
        class="preview button-plain"
        @click="$emit('preview')"
      >
        <icon-set icon="eye" />
        <span class="previewtext">{{ $t('details.header.pdfPreview') }}</span>
      </button>

      <template v-if="edit">
        <button
          type="button"
          class="button-primary save"
          @click="$emit('save')"
        >
          {{ $t('details.header.save') }}
        </button>

        <span
          class="cancel"
          @click="triggerCancel"
        >{{ $t('details.header.cancel') }}</span>
      </template>
    </div>

    <div class="meta">
      <div class="shortname">
        {{ project.shortName }}
      </div>

      <label
        v-if="edit"
        class="form-select -mono"
      >
        <span class="label">{{ $t('details.header.status') }}</span>

        <select
          v-model="localState.fundingStatus"
          class="select"
          @change="onFundingStatusChanged(localState.fundingStatus)"
        >
          <option
            v-for="(status, index) in fundingStates"
            :key="`funding-state-${index}`"
            :selected="fundingStatus && status.id === fundingStatus.id"
            :value="status"
          >
            {{ status.name }}
          </option>
        </select>
      </label>
      <div
        v-else-if="project.status && edit === false"
        class="status -funding"
      >
        {{ project.status.name }}
      </div>

      <tag-item
        v-if="project.projectStatus"
        class="status -internal -mono"
        :tag="project.projectStatus"
      />

      <template v-if="canChangeReadyInternalAtDate">
        <label>{{ $t('details.header.readyInternalAt') }}:</label>
        <form-date
          v-if="edit"
          v-model="localState.readyInternalAt"
          class="-inverted"
          :inline="false"
          mode="single"
          @change="triggerChange({ readyInternalAt: localState.readyInternalAt })"
        />
        <div v-else>
          {{ localState.readyInternalAt|date }}
        </div>
      </template>
    </div>

    <div class="languages">
      <button
        v-for="(item, index) in supported"
        :key="`language-${index}`"
        :class="{ language: true, '-active': item === language }"
        type="button"
        @click="triggerChange({ language: item })"
      >
        {{ item }}
      </button>

      <button
        v-if="edit"
        type="button"
        class="translations"
        @click="$emit('translate')"
      >
        {{ $t('details.header.translations') }}
      </button>
    </div>

    <tag-list
      :tags="[
        project.mountainZone,
        project.place,
        project.region,
        project.canton,
        ...project.languages.map((language) => $t(`label.${language}`)),
        [project.hasImages, $t('label.hasImages')],
        [project.hasChildren, $t('label.involvesChildren')],
        [project.areYoungApplicants, $t('label.youngApplicantsOnly')],
        `${$t('label.usagesInLists')}: ${project.usagesInLists}`,
      ]"
      class="tags"
    >
      <tag-item
        v-if="project.area"
        :tag="project.area"
        :icon="project.area.id"
        class="tag -primary"
      />
    </tag-list>

    <div
      v-if="project.decisionDate"
      class="decisiondate"
    >
      {{ $t('label.decisionDate') }}

      <tag-item
        class="date"
        :tag="project.decisionDate"
      />
    </div>
    <div class="translator">
      {{ $t('label.translationPerson') }}
      <label
        v-if="edit"
        class="select-translator form-select -mono"
      >
        <select
          v-model="localState.translator"
          class="select"
          @change="triggerChange({ translator: localState.translator })"
        >
          <option
            v-for="(sextantTranslator, index) in translators"
            :key="`translator-${index}`"
            :selected="sextantTranslator && translator && sextantTranslator.id === translator.id"
            :value="sextantTranslator"
          >
            {{ sextantTranslator.name }}
          </option>
        </select>
      </label>
      <div
        v-else-if="project.translator && edit === false"
        class="select-translator"
      >
        {{ project.translator.name }}
      </div>
    </div>
    <div class="copywriter">
      {{ $t('label.copywritingPerson') }}
      <label
        v-if="edit"
        class="select-copywriter form-select -mono"
      >
        <select
          v-model="localState.copywriter"
          class="select"
          @change="triggerChange({ copywriter: localState.copywriter })"
        >
          <option
            v-for="(sextantCopywriter, index) in copywriters"
            :key="`copywriter-${index}`"
            :selected="sextantCopywriter && copywriter && sextantCopywriter.id === copywriter.id"
            :value="sextantCopywriter"
          >
            {{ sextantCopywriter.name }}
          </option>
        </select>
      </label>
      <div
        v-else-if="project.copywriter && edit === false"
        class="select-copywriter"
      >
        {{ project.copywriter.name }}
      </div>
    </div>
  </header>
</template>

<script>
  import Project from '@/domain/project/Project';
  import { supported } from '@/domain/master-data/Language';

  import FormToggle from '@/components/form/FormToggle.vue';
  import TagItem from '@/components/TagItem.vue';
  import TagList from '@/components/TagList.vue';
  import IconSet from '@/components/IconSet.vue';
  import ToggleFavorite from '@/components/ToggleFavorite.vue';
  import ToggleProjectList from '@/components/ToggleProjectList.vue';
  import ProjectFundingStatus from '@/domain/master-data/ProjectFundingStatus';
  import Translator from '@/domain/master-data/Translator';
  import Copywriter from '@/domain/master-data/Copywriter';
  import FormDate from '@/components/form/FormDate.vue';
  import { format as date } from '@/domain/date/Date';

  export default {
    name: 'ProjectDetailsHeader',
    components: {
      FormDate,
      ToggleProjectList,
      FormToggle,
      TagItem,
      TagList,
      IconSet,
      ToggleFavorite,
    },
    filters: {
      date,
    },
    props: {
      project: {
        type: Project,
        required: true,
      },
      fundingStatus: {
        type: ProjectFundingStatus,
        default: null,
      },
      readyInternalAt: {
        type: Date,
        default: null,
      },
      translator: {
        type: Translator,
        default: new Translator({ id: 0, name: 'none' }),
      },
      copywriter: {
        type: Copywriter,
        default: new Copywriter({ id: 0, name: 'none' }),
      },
      language: {
        type: String,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      fundingStates: {
        type: Array,
        required: true,
      },
      translators: {
        type: Array,
        required: true,
      },
      copywriters: {
        type: Array,
        required: true,
      },
      useFavorite: {
        type: Boolean,
        default: false,
      },
      useProjectList: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        supported,
        edit: this.editMode,
        localState: {
          fundingStatus: this.fundingStatus,
          translator: this.translator,
          copywriter: this.copywriter,
          readyInternalAt: this.readyInternalAt,
        },
      };
    },
    computed: {
      isEmpty() {
        return typeof this.header !== 'string' || this.header === '';
      },
      canChangeReadyInternalAtDate() {
        return ProjectFundingStatus.isFundingStatusReadyInternalOrLater(this.fundingStatus);
      },
    },
    watch: {
      editMode(value) {
        this.edit = value;
      },
    },
    created() {
      this.triggerChange({ language: this.language });
    },
    methods: {
      triggerCancel() {
        this.$emit('cancel');
        this.localState = {
          fundingStatus: this.fundingStatus,
          translator: this.translator,
          copywriter: this.copywriter,
          readyInternalAt: this.readyInternalAt,
        };
      },
      triggerChange(data) {
        this.$emit('change', data);
      },
      onFundingStatusChanged(fundingStatus) {
        const data = { fundingStatus };
        if (fundingStatus) {
          if (ProjectFundingStatus.isFundingStatusReadyInternalOrLater(fundingStatus)) {
            this.localState.readyInternalAt = new Date();
          } else {
            this.localState.readyInternalAt = null;
          }

          data.readyInternalAt = this.localState.readyInternalAt;
        }

        this.triggerChange(data);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@gridonic/components/styles/button-plain";
  @import "~@/sass/meta";

  .project-details-header {
    display: grid;
    grid-column-gap: 4space;
    grid-template-areas:
        "back           ."
        "title          actions"
        "meta           actions"
        "ready          actions"
        "languages      actions"
        "tags           ."
        "decisiondate   ."
        "translator     ."
        "copywriter     ."
    ;
    grid-template-columns: 2fr 1fr;

    > .actions {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      grid-area: actions;

      > .save {
        margin-top: 3space;
      }

      > .cancel {
        margin-top: 1.8125space;
      }

      > .preview {
        margin-top: 2space;
        display: flex;
        align-items: center;
        padding: 0;

        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;
        color: $sbh-palette-white;
        cursor: pointer;

        > .previewtext {
          margin-left: 1space;
        }
      }
    }

    > .title {
      align-items: center;
      color: currentColor;
      display: flex;
      grid-area: title;
      margin-bottom: 3.625space;
      margin-top: 2.4375space;

      > .text {
        color: currentColor;
        font-size: 23px;
        font-weight: bold;
        line-height: 1.3;
      }

      > .actions {
        margin-left: 2space;

        > .favorite {
          margin-right: 0.75space;
        }
      }
    }

    > .meta {
      align-items: center;
      display: grid;
      grid-area: meta;
      grid-column-gap: 4space;
      grid-template-columns: repeat(3, max-content);
      grid-row-gap: 1space;

      > .status.-funding {
        color: $palette-fir-10;
      }
    }

    > .languages {
      grid-area: languages;
      margin-top: 2.625space;

      > .language {
        @include gd-button-plain;

        background-color: $sbh-palette-white;
        color: $palette-fir-50;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1.21;
        padding: 0.375space 1space;
        text-transform: uppercase;

        &.-active {
          background-color: $sbh-palette-fir;
          box-shadow: 0 3px 6px 0 #00000019, 0 10px 20px 0 #00000026;
          color: $sbh-palette-white;
        }
      }

      > .translations {
        @include gd-button-plain;

        text-decoration: underline;
        margin-left: 2.6875space;
      }
    }

    > .tags {
      grid-area: tags;
      margin-top: 2.4375space;
    }

    > .decisiondate {
      display: flex;
      grid-area: decisiondate;
      margin-top: 2.625space;

      > .date {
        margin-left: 1space;
      }
    }
    > .translator {
      display: flex;
      grid-area: translator;
      margin-top: 2.625space;

      > .select-translator {
        margin-left: 1space;
      }
    }
    > .copywriter {
      display: flex;
      grid-area: copywriter;
      margin-top: 2.625space;

      > .select-copywriter {
        margin-left: 1space;
      }
    }
  }
</style>
