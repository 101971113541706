import Partner from '@/domain/user/Partner';
import Project from '@/domain/project/Project';
import Reservation from '@/domain/donor/Reservation';

export default class ReservationConverter {
  constructor(masterData, donorConverter) {
    this.masterData = masterData;
    this.donorConverter = donorConverter;
  }

  convertReservation(responseData) {
    return new Reservation(
      {
        ...responseData,
        status: responseData.status
          ? this.masterData.getReservationStatus(responseData.status) : null,
        manager: responseData.manager ? new Partner(responseData.manager) : null,
        donor: responseData.donor ? this.donorConverter.convertDonor(responseData.donor) : null,
        project: responseData.project ? new Project(responseData.project) : null,
        valueDate: responseData.valueDate
          ? new Date(responseData.valueDate * 1000) : null,
        untilDate: responseData.untilDate
          ? new Date(responseData.untilDate * 1000) : null,
      },
    );
  }

  /**
   * @param {Reservation} reservation
   */
  requestDataFromReservation(reservation) {
    return {
      status: reservation.status ? reservation.status.id : null,
      project: reservation.project ? reservation.project.id : null,
      donor: reservation.donor ? reservation.donor.id : null,
      amount: reservation.amount,
      note: reservation.note,
      untilDate: reservation.untilDate
        ? reservation.untilDate.getTime() / 1000 : null,
    };
  }
}
