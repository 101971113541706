import NotificationType from '@/domain/notification/NotificationType';
import NotificationAction from '@/domain/notification/NotificationAction';

export default class ProjectListModule {
  /**
   * @param {ProjectListRepository} projectListRepository
   * @param {UserRepository} userRepository
   * @param {NotificationBus} notificationBus
   * @param {VueRouter} router
   */
  constructor({
    projectListRepository,
    userRepository,
    notificationBus,
    router,
  }) {
    this.projectListRepository = projectListRepository;
    this.userRepository = userRepository;
    this.notificationBus = notificationBus;
    this.router = router;
  }

  get namespaced() {
    return true;
  }

  get state() {
    return {
      activeList: null,
      user: this.userRepository.current,
    };
  }

  get getters() {
    return {
      [ProjectListModule.Getters.activeList]: (state) => state.activeList,
      [ProjectListModule.Getters.activeListProjectCount]:
        (state) => (state.activeList ? state.activeList.projects.length : 0),
      [ProjectListModule.Getters.hasUnpersistedChanges]: (state) => {
        if (state.activeList === null) {
          return false;
        }

        return state.activeList.persisted !== true;
      },
      [ProjectListModule.Getters.activeDonor]:
        (state) => (state.activeList ? state.activeList.donor : null),
    };
  }

  get mutations() {
    return {
      [ProjectListModule.Mutations.setActiveList]:
        (state, activeList) => { state.activeList = activeList; },
    };
  }

  get actions() {
    return {
      [ProjectListModule.Actions.loadActiveList]: async ({ commit, state }) => {
        if (!state.user.initialActiveProjectListId) {
          return null;
        }
        return commit(
          ProjectListModule.Mutations.setActiveList,
          await this.projectListRepository.readProjectList(state.user.initialActiveProjectListId),
        );
      },
      [ProjectListModule.Actions.updateActiveList]: async ({ commit }) => commit(
        ProjectListModule.Mutations.setActiveList,
        await this.projectListRepository.getActiveProjectListForCurrentUser(),
      ),
      [ProjectListModule.Actions.createAndActivateProjectList]: async (
        { dispatch, state }, { donor, force },
      ) => {
        if (this.unpersistedChangesGuard(
          state, dispatch, null,
          ProjectListModule.Actions.createAndActivateProjectList, { donor }, force,
        )) {
          return null;
        }

        const newList = await this.projectListRepository.createProjectList(donor);
        const newListId = newList.id;

        return dispatch(ProjectListModule.Actions.activateProjectList, {
          listId: newListId,
          target: { name: 'projectlist', params: { listId: newListId } },
          force: true,
        });
      },
      [ProjectListModule.Actions.duplicateAndActivateList]: async (
        { dispatch, state }, { listId, force },
      ) => {
        if (this.unpersistedChangesGuard(
          state, dispatch, listId,
          ProjectListModule.Actions.duplicateAndActivateList, { listId }, force,
        )) {
          return null;
        }

        const duplicatedList = await this.projectListRepository.duplicateProjectList(listId);
        const newListId = duplicatedList.id;

        return dispatch(ProjectListModule.Actions.activateProjectList, {
          listId: newListId,
          target: { name: 'projectlist', params: { listId: newListId } },
          force: true,
        });
      },
      [ProjectListModule.Actions.activateProjectList]: async (
        { commit, dispatch, state }, { listId, target, force },
      ) => {
        if (this.unpersistedChangesGuard(
          state, dispatch, listId,
          ProjectListModule.Actions.activateProjectList, { listId, target }, force,
        )) {
          return;
        }

        const finalListId = listId;

        let activated = false;
        if (!state.activeList || state.activeList.id !== finalListId) {
          const newList = await this.projectListRepository.setActiveProjectListForCurrentUser(finalListId);
          activated = newList && newList.id === finalListId;

          commit(
            ProjectListModule.Mutations.setActiveList,
            newList,
          );
        }

        if (target) {
          await this.router.push(target);
        }

        if (activated) {
          await this.notificationBus.notify(
            NotificationType.SUCCESS,
            'notification.projectList.activated.message',
          );
        }
      },
      [ProjectListModule.Actions.deactivateProjectList]: async (
        { commit },
      ) => {
        commit(ProjectListModule.Mutations.setActiveList, null);
      },
      [ProjectListModule.Actions.toggleProjectList]: async ({ dispatch, state }, projectId) => {
        const shouldAdd = state.activeList !== null && !state.activeList.containsProject(projectId);

        if (shouldAdd) {
          return dispatch(ProjectListModule.Actions.addProjectToList, projectId);
        }
        return dispatch(
          ProjectListModule.Actions.removeProjectsFromList,
          { projectIds: [projectId], reservationOnly: false },
        );
      },
      [ProjectListModule.Actions.addProjectToList]: async ({ commit, state }, projectId) => {
        let list = state.activeList;

        if (list === null || !list.id) {
          return;
        }

        list = await this.projectListRepository.addProjectToList(list.id, projectId);

        commit(ProjectListModule.Mutations.setActiveList, list);
      },
      [ProjectListModule.Actions.removeProjectsFromList]:
          async ({ commit, state }, { projectIds, reservationOnly }) => {
            let list = state.activeList;

            if (list === null || !list.id) {
              return;
            }

            list = await this.projectListRepository.removeProjectsFromList(list.id, { projectIds, reservationOnly });

            commit(ProjectListModule.Mutations.setActiveList, list);
          },
    };
  }

  /** @private */
  async createAndActivateNewProjectList() {
    const newList = await this.projectListRepository.createProjectList();

    return this.projectListRepository.setActiveProjectListForCurrentUser(newList.id);
  }

  /** @private */
  unpersistedChangesGuard(state, dispatch, listId, action, data, force) {
    if (force) {
      return false;
    }

    if (!state.activeList) {
      return false;
    }

    if (state.activeList.id === listId) {
      return false;
    }

    if (state.activeList.isPersisted) {
      return false;
    }

    this.notificationBus.notify(
      NotificationType.DANGER,
      'notification.projectList.unsaved.message', [
        new NotificationAction({
          text: 'notification.common.continue',
          handler: () => dispatch(action, { ...data, force: true }),
        }),
      ],
    );

    return true;
  }

  static get Namespace() {
    return 'projectList';
  }

  static get Getters() {
    return {
      activeList: 'activeList',
      activeDonor: 'activeDonor',
      activeListProjectCount: 'activeListProjectCount',
      hasUnpersistedChanges: 'hasUnpersistedChanges',
    };
  }

  static get Mutations() {
    return {
      setActiveList: 'setActiveList',
    };
  }

  static get Actions() {
    return {
      loadActiveList: 'loadActiveList',
      updateActiveList: 'updateActiveList',
      createAndActivateProjectList: 'createAndActivateProjectList',
      duplicateAndActivateList: 'duplicateAndActivateList',
      activateProjectList: 'activateProjectList',
      deactivateProjectList: 'deactivateProjectList',
      toggleProjectList: 'toggleProjectList',
      addProjectToList: 'addProjectToList',
      removeProjectsFromList: 'removeProjectsFromList',
    };
  }
}
