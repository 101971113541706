<template>
  <icon-set
    v-if="isLoading === false"
    :icon="icon"
    class="toggle-favorite"
    @click.native.prevent="toggleFavorite"
  />
</template>

<script>
  import { createNamespacedHelpers } from 'vuex';

  import IconSet from '@/components/IconSet.vue';

  import Project from '@/domain/project/Project';
  import FavoriteModule from '@/domain/user/FavoriteModule';

  const { mapGetters, mapActions } = createNamespacedHelpers(FavoriteModule.Namespace);

  export default {
    name: 'ToggleFavorite',
    components: {
      IconSet,
    },
    props: {
      project: {
        type: Project,
        required: true,
      },
    },
    computed: {
      ...mapGetters([
        FavoriteModule.Getters.favorites,
      ]),
      isActive() {
        return this.favorites.find(({ projectId }) => projectId === this.project.id) !== undefined;
      },
      isLoading() {
        return this.favorites === null;
      },
      icon() {
        return this.isActive ? 'heart-fill' : 'heart';
      },
    },
    methods: {
      ...mapActions([
        FavoriteModule.Actions.toggle,
      ]),
      async toggleFavorite() {
        if (this.isLoading) {
          return null;
        }

        return this.toggle(this.project.id);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .toggle-favorite {
    cursor: pointer;
  }
</style>
