<template>
  <div
    v-if="paginationRequired"
    class="result-pagination"
  >
    <div class="count">
      {{ $t('label.result') }} {{ firstResultIndex }} - {{ lastResultIndex }}
    </div>

    <div class="paging">
      <button
        v-if="currentPage > 0"
        type="button"
        class="button -back"
        @click="selectPage(currentPage - 1)"
      >
        {{ $t('label.back') }}
      </button>

      <ul class="pages">
        <template
          v-for="index in totalPages"
        >
          <li
            v-if="shouldPageButtonBeVisible(index - 1)"
            :key="`page-${index}`"
            class="page"
          >
            <button
              type="button"
              :class="[ `page-button -${index - 1 === currentPage ? 'active' : 'page '}` ]"
              @click="selectPage(index - 1)"
            >
              {{ index }}
            </button>
          </li>

          <li
            v-if="shouldEllipsisBeVisible(index - 1)"
            :key="`page-ellipsis-start-${index}`"
            class="ellipsis"
          >
            …
          </li>
        </template>
      </ul>

      <button
        v-if="currentPage < totalPages - 1"
        type="button"
        class="button -forward"
        @click="selectPage(currentPage + 1)"
      >
        {{ $t('label.forward') }}
      </button>
    </div>
  </div>
</template>

<script>
  const VISIBLE_PAGE_BUTTONS_THRESHOLD = 2;

  export default {
    name: 'ResultPagination',
    model: {
      prop: 'currentPage',
      event: 'page-selected',
    },
    props: {
      totalHits: {
        type: Number,
        default: 0,
      },
      totalPages: {
        type: Number,
        default: 0,
      },
      hitsPerPage: {
        type: Number,
        default: 0,
      },
      currentPage: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      paginationRequired() {
        return this.totalHits > this.hitsPerPage;
      },
      firstResultIndex() {
        return this.currentPage * this.hitsPerPage + 1;
      },
      lastResultIndex() {
        return Math.min(
          this.firstResultIndex + this.hitsPerPage - 1,
          this.totalHits,
        );
      },
    },
    methods: {
      selectPage(page) {
        this.$emit('page-selected', page);
      },
      shouldPageButtonBeVisible(index) {
        const walkToPrev = this.currentPage - Math.max(0, this.currentPage - VISIBLE_PAGE_BUTTONS_THRESHOLD);
        const walkToNext = Math.min(this.totalPages - 1, this.currentPage + VISIBLE_PAGE_BUTTONS_THRESHOLD)
          - this.currentPage;

        return index === 0
          || index === this.totalPages - 1
          || (index >= this.currentPage - (walkToPrev + VISIBLE_PAGE_BUTTONS_THRESHOLD - walkToNext)
            && index <= this.currentPage + (walkToNext + VISIBLE_PAGE_BUTTONS_THRESHOLD - walkToPrev));
      },
      shouldEllipsisBeVisible(index) {
        return (index === 0 && !this.shouldPageButtonBeVisible(1))
          || (index === this.totalPages - 2
            && !this.shouldPageButtonBeVisible(this.totalPages - 2));
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .result-pagination {
    display: flex;
    justify-content: stretch;
    align-items: center;
    font-size: 0.8em;

    > .paging {
      display: flex;
      margin-left: auto;
    }

    > .paging > .pages {
      display: flex;
      margin: -0.5space;
      padding: 0;
      list-style: none;

      > .page {
        margin: 0.5space;
      }

      > .ellipsis {
        display: flex;
        align-items: flex-end;
        padding-bottom: 0.5rem;
        margin: 0 0.25rem;
      }
    }

    > .paging > .button {
      all: unset;
      color: $sbh-palette-fir;
      text-decoration: underline;

      &.-back {
        margin-right: 2space;
      }

      &.-forward {
        margin-left: 2space;
      }

    }

    &.-inverted > .paging > .button {
      color: $sbh-palette-white;
    }
  }

  .page-button {
    border: 1px solid $sbh-palette-fir;
    border-radius: 0;
    padding: 0.5space 0.75space;
    background-color: $color-background;
    color: $sbh-palette-fir;

    @media (hover: hover) {
      &:hover {
        box-shadow: 0 10px 20px -6px rgba(0, 0, 0, 0.5);
      }
    }

    &.-active {
      background-color: $sbh-palette-fir;
      color: $color-background;
      box-shadow: 0 10px 20px -6px rgba(0, 0, 0, 0.5);
    }
  }
</style>
