<template>
  <div class="project-details-contact-information">
    <strong
      v-if="applicant"
      class="title"
    >{{ $t('label.projectOwner') }}</strong>

    <contact-card
      v-if="applicant"
      class="person"
      :title="applicant.name"
      :street="applicant.street"
      :street-number="applicant.streetNumber"
      :zip="applicant.zip"
      :place="applicant.place"
      :phone="applicant.phone"
      :email="applicant.email"
    />

    <span
      v-if="applicant && (expert || copywriter || translator)"
      class="title -border"
    />

    <contact-card
      v-if="expert"
      class="person"
      :title="$t('label.expert')"
      :name="expert.name"
      :street="expert.street"
      :street-number="expert.streetNumber"
      :zip="expert.zip"
      :place="expert.place"
      :phone="expert.phone"
      :email="expert.email"
    />

    <contact-card
      v-if="copywriter"
      class="person"
      :title="$t('label.copywriter')"
      :name="copywriter.name"
      :street="copywriter.street"
      :street-number="copywriter.streetNumber"
      :zip="copywriter.zip"
      :place="copywriter.place"
      :phone="copywriter.phone"
      :email="copywriter.email"
    />

    <contact-card
      v-if="translator"
      class="person"
      :title="$t('label.translator')"
      :name="translator.name"
      :street="translator.street"
      :street-number="translator.streetNumber"
      :zip="translator.zip"
      :place="translator.place"
      :phone="translator.phone"
      :email="translator.email"
    />
  </div>
</template>

<script>
  import ContactCard from '@/components/ContactCard.vue';

  export default {
    name: 'ProjectDetailsContactInformation',
    components: {
      ContactCard,
    },
    props: {
      applicant: {
        type: Object,
        default: null,
      },
      expert: {
        type: Object,
        default: null,
      },
      copywriter: {
        type: Object,
        default: null,
      },
      translator: {
        type: Object,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-contact-information {
    &:empty {
      display: none;
    }

    > .title {
      color: $sbh-palette-fir;
      display: block;

      &.-border {
        border-top: 1px solid $palette-fir-20;
        padding-top: 1.5space;
      }
    }

    > .person {
      margin: 1.5space 0;

      + .person {
        margin-top: 2.5space;
      }
    }
  }
</style>
