export default class Partner {
  constructor(data) {
    this.data = data;
  }

  /**
   * @returns {int}
   */
  get id() {
    return this.data.id;
  }

  /**
   * @returns {int}
   */
  get sextantId() {
    return this.data.sextantId;
  }

  /**
   * @returns {int}
   */
  get externalId() {
    return this.data.externalId;
  }

  /**
   * @returns {string}
   */
  get salutation() {
    return this.data.salutation;
  }

  /**
   * @returns {string}
   */
  get firstname() {
    return this.data.firstname ?? null;
  }

  /**
   * @returns {string}
   */
  get lastname() {
    return this.data.lastname ?? null;
  }

  /**
   * @returns {string}
   */
  get companyName() {
    return this.data.companyName ?? null;
  }

  /**
   * @returns {string}
   */
  get name() {
    return [this.firstname, this.lastname]
      .filter((n) => n)
      .join(' ');
  }

  /**
   * @returns {string}
   */
  get nameReversed() {
    return [this.lastname, this.firstname]
      .filter((n) => n)
      .join(', ');
  }

  /**
   * @returns {string}
   */
  get email() {
    return this.data.email ?? null;
  }

  /**
   * @returns {string}
   */
  get street() {
    return this.data.street ?? null;
  }

  /**
   * @returns {string}
   */
  get streetNumber() {
    return this.data.streetNumber ?? null;
  }

  /**
   * @returns {string}
   */
  get zip() {
    return this.data.zip ?? null;
  }

  /**
   * @returns {string}
   */
  get place() {
    return this.data.place ?? null;
  }

  /**
   * @returns {string}
   */
  get phone() {
    return this.data.phone ?? null;
  }

  /**
   * @returns {Object}
   */
  get allPhones() {
    return this.data.allPhones;
  }

  /**
   * @returns {string}
   */
  get language() {
    return this.data.language || 'de';
  }
}
