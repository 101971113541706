export default class Favorite {
  constructor(data) {
    this._id = data.id;
    this._userId = data.userId;
    this._projectId = data.projectId;
    this._project = data.project;
  }

  /**
   * @returns {int}
   */
  get id() {
    return this._id;
  }

  /**
   * @returns {int}
   */
  get userId() {
    return this._userId;
  }

  /**
   * @returns {int}
   */
  get projectId() {
    return this._projectId;
  }

  /**
   * @return {Project}
   */
  get project() {
    return this._project;
  }
}
