export default class Coordinates {
  constructor(data) {
    this._latitude = data.latitude || null;
    this._longitude = data.longitude || null;
  }

  /**
   * @returns {number}
   */
  get latitude() {
    return this._latitude;
  }

  /**
   * @returns {number}
   */
  get longitude() {
    return this._longitude;
  }
}
