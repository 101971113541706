<template>
  <div class="project-details-copy">
    <content-editor
      class="title h3"
      :editable="editMode"
      :formattable="false"
      :content="title"
      :placeholder="$t('details.copy.titles')"
      :recommended-char-limit="recommendedCharLimits.title"
      @change="$emit('change', { localizedTitles: $event.json })"
    />

    <content-editor
      class="lead"
      :editable="editMode"
      :formattable="false"
      :content="lead"
      :placeholder="$t('details.copy.leads')"
      :recommended-char-limit="recommendedCharLimits.lead"
      @change="$emit('change', { localizedLeads: $event.json })"
    />

    <content-editor
      class="texts"
      :editable="editMode"
      :content="text"
      :placeholder="$t('details.copy.texts')"
      :recommended-char-limit="recommendedCharLimits.texts"
      @change="$emit('change', { localizedTextPmModels: $event.json })"
    />

    <div class="acknowledgments">
      <div>
        <strong>{{ $t('details.copy.acknowledgments') }}</strong>
      </div>

      <content-editor
        class="acknowledgmentseditor"
        :editable="editMode"
        :formattable="false"
        :content="acknowledgment"
        :placeholder="$t('details.copy.acknowledgments')"
        :recommended-char-limit="recommendedCharLimits.acknowledgments"
        @change="$emit('change', { localizedAcknowledgments: $event.json })"
      />
    </div>
  </div>
</template>

<script>
  import ContentEditor from '@/components/ContentEditor.vue';
  import { recommendedCharLimits } from '@/domain/project/Project';

  export default {
    name: 'ProjectDetailsCopy',
    components: {
      ContentEditor,
    },
    props: {
      title: {
        type: [Object, String],
        default: '',
      },
      lead: {
        type: [Object, String],
        default: '',
      },
      text: {
        type: [Object, String],
        default: '',
      },
      acknowledgment: {
        type: [Object, String],
        default: '',
      },
      editMode: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      recommendedCharLimits: () => recommendedCharLimits,
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";

  .project-details-copy {
    &:empty {
      display: none;
    }

    > .title ::v-deep p {
      color: $sbh-palette-fir;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 2space;
    }

    > .lead ::v-deep p {
      margin: 2space 0 2.625space;
      font-weight: bold;
    }

    > .texts {
      font-size: 18px;
      line-height: 1.56;
    }

    > .acknowledgments {
      border-top: 2px solid $sbh-palette-timberwolf;

      margin-top: 3space;
      padding-top: 2space;

      > .acknowledgmentseditor {
        margin-top: 1space;
      }
    }
  }
</style>
