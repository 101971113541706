<template>
  <div class="project">
    <div class="header">
      <img
        v-if="project.canton"
        width="200"
        :src="cantonImageUrl(project.canton)"
        alt=""
        class="image"
      >

      <h2 class="title -brand">
        {{ projectTitle(project) }}
      </h2>

      <p class="lead">
        {{ projectLead(project) }}
      </p>
    </div>

    <content-editor
      :editable="false"
      :content="projectDescription(project)"
      :compact="true"
    />

    <div
      class="information"
    >
      <dl class="attributes">
        <dt class="term">
          {{ $t('export.projectList.projectPage.attributes.code') }}
        </dt>
        <dd class="definition">
          {{ project.shortName }}
        </dd>

        <dt class="term">
          {{ $t('export.projectList.projectPage.attributes.canton') }}
        </dt>
        <dd class="definition">
          {{ project.canton ? project.canton.name : '' }}
        </dd>

        <dt class="term">
          {{ $t('export.projectList.projectPage.attributes.region') }}
        </dt>
        <dd class="definition">
          {{ project.region ? project.region.name : '' }}
        </dd>

        <template v-if="displayReservation">
          <dt
            class="term -compact"
          >
            <strong><span>{{ $t('export.projectList.projectPage.attributes.reservedAmount') }}</span>
              <span> {{ reservation.untilDate|date(locale) }}</span></strong>
          </dt>
          <dd
            class="definition"
          >
            <strong>{{ reservation.amount|currency }}</strong>
          </dd>
        </template>

        <dt class="term">
          <strong>
            <template v-if="displayReservation">
              {{ $t('export.projectList.projectPage.attributes.outstandingBalanceWithReservation') }}
            </template>
            <template v-else>
              {{ $t('export.projectList.projectPage.attributes.outstandingBalanceWithoutReservation') }}
            </template>
          </strong>
        </dt>
        <dd class="definition">
          <strong>
            {{
              (project.financePlan ? (project.financePlan.outstandingBalance) : '-')|currency
            }}
          </strong>

          <tag-item
            v-if="project.isApprovedButIncompletelyPayed(project.financePlan)"
            class="-alert"
            :tag="$t('label.projectApprovedButIncompletelyPayed.long')"
          />
        </dd>
      </dl>

      <figure
        v-if="projectImage(project)"
        class="image"
      >
        <img
          :src="projectImageUrl(project)"
          alt=""
        >
        <figcaption v-if="projectImageCaption(project)">
          {{ projectImageCaption(project) }}
        </figcaption>
      </figure>
      <figure
        v-else
        class="image"
      >
        <img src="@/assets/images/export/no-image.png">
      </figure>
    </div>
  </div>
</template>

<script>
  import { format as currency } from '@/domain/currency/Currency';
  import { formatVerbose as date } from '@/domain/date/Date';

  import ContentEditor from '@/components/ContentEditor.vue';
  import TagItem from '@/components/TagItem.vue';

  import Project from '@/domain/project/Project';
  import Reservation from '@/domain/donor/Reservation';

  export default {
    name: 'ProjectListExportProject',
    components: { TagItem, ContentEditor },
    filters: {
      currency,
      date,
    },
    props: {
      project: {
        type: Project,
        required: true,
      },
      reservation: {
        type: Reservation,
        default: null,
      },
      useReservations: {
        type: Boolean,
        default: false,
      },
      locale: {
        type: String,
        required: true,
      },
      accessToken: {
        type: String,
        default: '',
      },
    },
    computed: {
      printDate() {
        return new Date();
      },
      displayReservation() {
        return this.useReservations && this.reservation && this.reservation.amount > 0;
      },
    },
    methods: {
      projectTitle(project) {
        return project.getLocalizedTitle(this.locale) || '!Kein Titel gesetzt!';
      },
      projectLead(project) {
        return project.getLocalizedLead(this.locale) || '!Kein Lead Text gesetzt!';
      },
      projectDescription(project) {
        return project.getLocalizedTextAsHtml(this.locale) || '!Keine Beschreibung vorhanden!';
      },
      projectImage(project) {
        return project.titleOrFallbackImage;
      },
      projectImageUrl(project) {
        const image = this.projectImage(project);

        if (!image) {
          return null;
        }

        return image.url;
      },
      projectImageCaption(project) {
        const image = this.projectImage(project);

        if (!image) {
          return null;
        }

        return image.localizedCaptions[this.locale] || image.localizedCaptions.de;
      },
      cantonImageUrl(canton) {
        return `/files/images/canton/${canton.id}.jpg`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/sass/meta";
  @import "@/sass/components/_project-list-export-layout";

  .project {
    position: relative;
    height: 100%;
    page-break-before: always;

    p {
      margin: 18px 0 22px;
    }

    > .header {
      position: relative;
      width: 100%;

      > .title {
        font-size: $ple-title-font-size;
        line-height: $ple-title-line-height;
      }

      > .lead {
        font-size: $ple-text-font-size;
        line-height: $ple-text-line-height;
        font-weight: bold;
      }

      > .image {
        position: absolute;
        top: -($ple-page-project-margin-top - $ple-canton-image-margin-top);
        right: 0;
        text-align: right;
        width: $ple-canton-image-width;
      }
    }

    > .information {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      font-size: $ple-attributes-font-size;
      line-height: $ple-attributes-line-height;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      min-height: 204px;

      > .attributes {
        display: grid;
        grid-template-columns: 1.1fr 1fr;
        margin: 0;
        width: 360px;

        > .term,
        > .definition {
          border-top: 1px solid #b5b5b5;
        }

        > .term {
          padding: 4px 12px 3px 0;

          &.-compact {
            letter-spacing: -0.8px;
          }
        }

        > .definition {
          padding: 4px 0 3px;
          margin: 0;
        }
      }

      > .image {
        margin: 0;
        font-size: $ple-image-caption-font-size;
        line-height: $ple-image-caption-line-height;
        width: 264px;

        img {
          width: 100%;
          height: 170px;
          object-fit: cover;
        }
      }
    }
  }
</style>
